import { npsenseApiDataHeaders, getNpsenseApiUrl } from './variables'

export const loginMixin = {
  methods: {
    async npsenseDoLogin (user, pass) {
      return await new Promise((resolve,reject) => {
        const userEncoded = user
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&apos;");
        const passEncoded = pass
          .replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&apos;");

        const dataRequest =
          '<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body>' +
          "<apiLogin><username>" +
          userEncoded +
          "</username>" +
          "<password>" +
          passEncoded +
          "</password>" +
          "</apiLogin></soap:Body></soap:Envelope>";

        const _app = this;

        fetch(getNpsenseApiUrl(), {
          method: "POST",
          headers: {
            ...npsenseApiDataHeaders,
            "Content-Type": "application/xml",
          },
          body: dataRequest, 
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Respuesta no exitosa desde el servidor");
            }
            return response.text();
          })
          .then((data) => {
            const parser = new DOMParser();
            const result = parser.parseFromString(data, "text/xml");
            _app.$store.dispatch("setLoginData", {
              user: user,
              pass: pass,
              token: result.getElementsByTagName("ns1:apiLoginResponse")[0]
                .textContent,
              time: new Date().getTime() + 20 * 60000,
            });
            var returnContent = result.getElementsByTagName("ns1:apiLoginResponse")[0]
            .textContent;
            resolve(returnContent);
          })
          // .then((token) => {
          //   // Haz algo con el token si es necesario
          //   console.log(token);
          // })
          .catch((error) => {
            console.error(`Acceso incorrecto (${error})`);
            reject(error);
          });
      })
    },
    async npsenseLogin(user, pass) {
      const firstLoginAttempt = !this.$store.getters.getLoginData.token;
      const currentTime = new Date().getTime();
      const expiredToken = this.$store.getters.getTokenTime < currentTime;
    
      if (firstLoginAttempt || expiredToken) {
        await this.npsenseDoLogin(user, pass);
      }
    }    
  }
}
