<template>
  <div class="dashboard-comp">
    <div class="topbar" :class="platform">
      <div class="close">
        <a href="#" class="btn btn-link btn-close" @click.prevent="hideModal">&times;</a>
      </div>
      <div class="titleCampaign">
        Campañas
        <p class="subtitle">
          {{ this.$store.state.currentName }}
        </p>
      </div>
    </div>

    <div class="fondoAzul dashboard-popup" :class="platform">
      <div v-if="campaigns.length > 0" key="campaigns">
        <v-touch v-for="(campaign, index) in campaignsAPI" :key="index" class="campaign text-center"
          @swiperight="openOptionsLeft" @swipeleft="openOptionsRight" @tap="closeOptions">
          <div class="frontOptions">
            <div class="container">
              <div class="grid">
                <div class="col-12 col-bleed-x">
                  <div class="zone">
                    {{ campaign.name }}
                  </div>
                  <div class="nps2">
                    {{ campaign.total_nps | addDecimal }}
                  </div>
                </div>
              </div>
              <div class="grid grid-bleed sat" @click="toggleValue">
                <div class="col-12">
                  <div class="sat1">
                    <img src="@/assets/images/bigico_npsTable_positive.png"
                      srcset="@/assets/images/bigico_npsTable_positive@2x.png 2x, @/assets/images/bigico_npsTable_positive@3x.png 3x"
                      width="16">
                    <transition name="fadefast" mode="out-in">
                      <span v-if="valuePercent" key="valuePercent">{{ campaign.total_promoters_pctg | addDecimal |
                        addPercent }}</span>
                      <span v-else key="valueTotal">{{ campaign.total_promoters }}</span>
                    </transition>
                  </div>
                  <div class="sat2">
                    <img src="@/assets/images/bigico_npsTable_neutral.png"
                      srcset="@/assets/images/bigico_npsTable_neutral@2x.png 2x, @/assets/images/bigico_npsTable_neutral@3x.png 3x"
                      width="16">
                    <transition name="fadefast" mode="out-in">
                      <span v-if="valuePercent" key="valuePercent">{{ campaign.total_passives_pctg | addDecimal |
                        addPercent }}</span>
                      <span v-else key="valueTotal">{{ campaign.total_passives }}</span>
                    </transition>
                  </div>
                  <div class="sat3">
                    <img src="@/assets/images/bigico_npsTable_negative.png"
                      srcset="@/assets/images/bigico_npsTable_negative@2x.png 2x, @/assets/images/bigico_npsTable_negative@3x.png 3x"
                      width="16">
                    <transition name="fadefast" mode="out-in">
                      <span v-if="valuePercent" key="valuePercent">{{ campaign.total_detractors_pctg | addDecimal |
                        addPercent }}</span>
                      <span v-else key="valueTotal">{{ campaign.total_detractors }}</span>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="backOptionsLeft">
            <div class="options">
              <a href="#" @click.prevent="viewComments(campaign.id, campaign.name)">
                <img src="@/assets/images/ico_table_comments.png"
                  srcset="@/assets/images/ico_table_comments@2x.png 2x, @/assets/images/ico_table_comments@3x.png 3x"
                  width="20"><br>
                Comentarios
              </a>
            </div>
          </div>
          <div class="backOptionsRight">
            <div class="options">
              <a href="#" @click.prevent="viewMotives(campaign.id, campaign.name)">
                <img src="@/assets/images/ico_table_motives.png"
                  srcset="@/assets/images/ico_table_motives@2x.png 2x, @/assets/images/ico_table_motives@3x.png 3x"
                  width="20"><br>
                Motivos
              </a>
            </div>
          </div>
        </v-touch>
      </div>
      <div v-else key="loading" class="text-center">
        <div class="spacer rem2" />
        <template v-if="errorUser">
          <p class="alert">
            Lo sentimos, el usuario no dispone de acceso a estas campañas.
          </p>
        </template>
        <template v-else>
          <img src="@/assets/images/loading.gif" width="21" alt="">
        </template>
      </div>
    </div>
  </div>
</template>


<script>
import { loginMixin } from '@/loginMixin'
import { platformMixin } from '@/platformMixin'

export default {
  filters: {
    addDecimal(value) {
      const valor = parseFloat(value)
      return valor.toFixed(2)
    },
    addPercent(value) {
      return `${value}%`
    }
  },
  mixins: [loginMixin, platformMixin],
  props: ['dashboardCampaigns'],
  data() {
    return {
      errorUser: false,
      valuePercent: true,
      dashboardComments: false,
      hierarchy: this.$store.state.currentHierarchy,
      campaigns: [],
      campaignsAPI: []
    }
  },
  watch: {
    campaigns() {
      this.getCampaignsData()
    }
  },
  created() {
    // Fabric Answers
    this.npsenseLogin(this.$store.state.login.user, this.$store.state.login.pass)
      .then(() => {
        setTimeout(() => {
          this.getCampaigns()
        }, 100)
      })
  },
  methods: {
    hideModal() {
      this.$emit('dashboard-campaigns-opened', false)
    },
    openOptionsLeft(e) {
      this.closeOptions()
      const el = e.target.closest('.campaign').querySelector('div.frontOptions')
      el.classList.add('open-right')
    },
    openOptionsRight(e) {
      this.closeOptions()
      const el = e.target.closest('.campaign').querySelector('div.frontOptions')
      el.classList.add('open-left')
    },
    closeOptions() {
      const els = document.getElementsByClassName('open-left')
      while (els[0]) {
        els[0].classList.remove('open-left')
      }
      const els2 = document.getElementsByClassName('open-right')
      while (els2[0]) {
        els2[0].classList.remove('open-right')
      }
    },
    async getCampaigns() {
      this.$Progress.start();

      // Comprobamos si es CENTER u otro
      let geoID = (this.hierarchy === 'center') ? '' : this.$store.state.currentID;
      let centerID = (this.hierarchy === 'center') ? this.$store.state.currentID : '';

      const dataRequest = `<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body>
                            <apiGetCampaigns>
                            <sessionkey>${this.$store.state.login.token}</sessionkey>
                            <geo_id>${geoID}</geo_id>
                            <center_id>${centerID}</center_id>
                            <answer_type>json</answer_type>
                            </apiGetCampaigns></soap:Body></soap:Envelope>`;

      try {
        const response = await fetch(this.npsenseApiUrl, {
          method: 'POST',
          headers: {
            ...this.npsenseApiDataHeaders,
            'Content-Type': 'application/xml'
          },
          body: dataRequest
        });

        if (!response.ok) {
          throw new Error('Respuesta no exitosa desde el servidor');
        }

        const data = await response.text();
        const parser = new DOMParser();
        const result = parser.parseFromString(data, 'text/xml');
        const campaignsResponse = JSON.parse(result.getElementsByTagName('ns1:apiGetCampaignsResponse')[0].textContent);
        const jsonTree = campaignsResponse.campaign;

        const tempCampaigns = [];
        if (jsonTree) {
          jsonTree.forEach((camps) => {
            tempCampaigns.push({
              id: camps['@attributes'].id,
              name: camps['@attributes'].name,
            });
          });
          this.campaigns = tempCampaigns;
        }
        this.$Progress.finish();

      } catch (error) {
        this.$Progress.fail();
        this.errorUser = true;
        console.log('Error', error);
        this.$router.push('/');
      }
    },
    async getCampaignsData() {
      // Comprobamos qué ha seleccionado el usuario para hacer la consulta
      let geoID = this.$store.state.currentID;
      let centerID = '';
      let waveID = '';

      if (this.$store.state.waveData.ID) {
        geoID = '';
        centerID = '';
        waveID = this.$store.state.waveData.ID;
      }

      if (this.hierarchy === 'center') {
        geoID = '';
        centerID = this.$store.state.currentID;
      }

      this.$Progress.start();

      for (let campID of this.campaigns) {
        const dataRequest = `<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body>
        <apiGetNPSData>
        <sessionkey>${this.$store.state.login.token}</sessionkey>
        <geo_id>${geoID}</geo_id>
        <center_id>${centerID}</center_id>
        <campaign_id>${campID.id}</campaign_id>
        <wave_id>${waveID}</wave_id>
        <answer_type>json</answer_type>
        </apiGetNPSData></soap:Body></soap:Envelope>`;

        try {
          const response = await fetch(this.npsenseApiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/xml',
              ...this.npsenseApiDataHeaders,
            },
            body: dataRequest
          });

          if (!response.ok) {
            throw new Error('Respuesta no exitosa desde el servidor');
          }

          const data = await response.text();
          const parser = new DOMParser();
          const result = parser.parseFromString(data, 'text/xml');
          const npsData = JSON.parse(result.getElementsByTagName('ns1:apiGetNPSDataResponse')[0].textContent);

          // Añadimos los campos ID y NAME
          npsData.id = campID.id;
          npsData.name = campID.name;

          // Lo enchufamos al data
          this.campaignsAPI.push(npsData);

        } catch (error) {
          console.log('Error:', error);
        }
      }

      this.$Progress.finish();
    },
    viewComments(campaignId, campaignName) {
      this.$emit('dynamic-campaign-id', campaignId)
      this.$emit('dynamic-campaign-name', campaignName)
      this.$emit('dashboard-comments-opened', true)
    },
    viewMotives(campaignId, campaignName) {
      this.$emit('dynamic-campaign-id', campaignId)
      this.$emit('dynamic-campaign-name', campaignName)
      this.$emit('dashboard-motives-opened', true)
    },
    toggleValue() {
      this.valuePercent = !this.valuePercent
    }
  }
}
</script>
