<template>
  <div class="config">
    <div class="topbar" :class="platform">
      <div class="container">
        <div class="grid grid-bleed">
          <div class="col-6">
            <router-link :to="{ name: 'ConfigCampaign' }" class="btn btn-link">
              Anterior
            </router-link>
          </div>
          <div class="col-6 text-right">
            <button class="btn btn-link" :disabled="nextDisabled()" @click="setGeoWave()">
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container fondoAzul" :class="platform">
      <div class="grid justify-center align-center">
        <div class="col-10">
          <div class="text-center">
            <img src="@/assets/images/big_ico_wave.png"
              srcset="@/assets/images/big_ico_wave@2x.png 2x, @/assets/images/big_ico_wave@3x.png 3x" width="100">
            <h1>¿Qué oleada te gustaría monitorizar?</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="fondoNegro config-process" :class="platform">
      <ul v-if="waves" class="list">
        <li v-for="(wave, key) in waves" :key="key">
          <label class="radio-label">
            {{ wave.name }}
            <input v-model="selectedWave" type="radio" :value="wave.id" class="list-checkbox"
              @change="setWaveName(wave.name)">
            <span class="radio-button" />
          </label>
        </li>
      </ul>
      <div v-else class="text-center">
        <div class="spacer rem2" />
        <img src="@/assets/images/loading.gif" width="21" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { platformMixin } from '@/platformMixin'

export default {
  mixins: [platformMixin],
  data() {
    return {
      selectedWave: null,
      waves: '',
      wavesAll: []
    }
  },
  created() {
    this.$Progress.start()
    this.getWaves()
  },
  methods: {
    nextDisabled() {
      return !this.selectedWave
    },
    getWaves() {
      // Obtenemos las oleadas del centro y la campaña seleccionada
      const dataRequest = '<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body>' +
        '<apiGetWaves>' +
        '<sessionkey>' + this.$store.state.login.token + '</sessionkey>' +
        '<center_id>' + this.$store.state.centerData.ID + '</center_id>' +
        '<campaign_id>' + this.$store.state.campaignData.ID + '</campaign_id>' +
        '<answer_type>json</answer_type></apiGetWaves></soap:Body></soap:Envelope>'

      const _app = this

      fetch(this.npsenseApiUrl, {
        method: 'POST',
        headers: this.npsenseApiDataHeaders,
        body: dataRequest
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then(data => {
          const parser = new DOMParser()
          const result = parser.parseFromString(data, 'text/xml')
          const wavesResponse = JSON.parse(result.getElementsByTagName('ns1:apiGetWavesResponse')[0].textContent)
          const jsonTree = wavesResponse.wave

          // Waves
          const tempWaves = []
          if (jsonTree) {
            jsonTree.forEach((camps) => {
              tempWaves.push({
                id: camps['@attributes'].id,
                name: camps['@attributes'].name,
              })
              _app.wavesAll.push({
                id: camps['@attributes'].id,
                name: camps['@attributes'].name,
              })
            })
            tempWaves.unshift({
              id: 'all',
              name: 'Todas las oleadas',
            })
          }
          _app.waves = tempWaves
          _app.$Progress.finish()
        })
        .catch(error => {
          _app.$Progress.fail()
          console.error('Error:', error)
          const parser = new DOMParser()
          const result = parser.parseFromString(error.message, 'text/xml')
          const errorResponse = result.getElementsByTagName('faultstring')[0]?.textContent || "Error desconocido"
          alert('Ha ocurrido un error: ' + errorResponse)
          _app.hideModal()
        });
    },
    setWaveName(name) {
      this.selectedWaveName = name
    },
    async setGeoWave() {
      // Si se selecciona "Todas" guardamos en blanco para API
      const selectedWave = this.selectedWave === 'all' ? '' : this.selectedWave
      const waveData = {
        ID: selectedWave,
        Name: this.selectedWaveName
      }
      await this.$store.dispatch('setWaveData', waveData)
      // Avanzamos
      this.$router.push({ name: 'Dashboard' })
    }
  }
}
</script>
