<template>
  <div class="config">
    <div
      class="topbar"
      :class="platform"
    >
      <div class="container">
        <div class="grid grid-bleed">
          <div class="col-6">
            <router-link
              class="btn btn-link"
              :to="{name: 'ConfigGeoHierarchy'}"
            >
              Anterior
            </router-link>
          </div>
          <div class="col-6 text-right">
            <button
              class="btn btn-link"
              :disabled="nextDisabled"
              @click="setGeoZone()"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container"
      style="height: auto !important"
    >
      <div
        class="grid fondoAzul config-process justify-center align-center"
        :class="platform"
      >
        <div class="col-10">
          <div class="content">
            <img
              src="@/assets/images/big_ico_center.png"
              srcset="@/assets/images/big_ico_center@2x.png 2x, @/assets/images/big_ico_center@3x.png 3x"
              width="100"
            >
            <h1>Selecciona una de las siguientes opciones</h1>
          </div>
        </div>
      </div>
    </div>

    <div
      class="fondoNegro config-process"
      :class="platform"
    >
      <div class="previousname">
        {{ previousName }}
      </div>
      <ul class="list">
        <template v-if="$store.state.geoSelected.value === 'continent'">
          <ul class="list">
            <li
              v-for="(continent, index) in continents"
              :key="index"
            >
              <label class="radio-label">
                {{ continent.name }}
                <input
                  v-model="selectedZone"
                  :value="continent.id"
                  type="radio"
                  class="list-checkbox"
                  @change="setZoneName(continent.name)"
                >
                <span class="radio-button" />
              </label>
            </li>
          </ul>
        </template>

        <template v-if="$store.state.geoSelected.value === 'country'">
          <ul class="list">
            <li
              v-for="(country, index) in countries"
              :key="index"
            >
              <label class="radio-label">
                {{ country.previousName }} / {{ country.name }}
                <input
                  v-model="selectedZone"
                  :value="country.id"
                  type="radio"
                  class="list-checkbox"
                  @change="setZoneName(country.name, country.previousName)"
                >
                <span class="radio-button" />
              </label>
            </li>
          </ul>
        </template>

        <template v-if="$store.state.geoSelected.value === 'zone'">
          <ul class="list">
            <li
              v-for="(zone, index) in zones"
              :key="index"
            >
              <label class="radio-label">
                {{ zone.previousName }} / {{ zone.name }}
                <input
                  v-model="selectedZone"
                  :value="zone.id"
                  type="radio"
                  class="list-checkbox"
                  @change="setZoneName(zone.name, zone.previousName)"
                >
                <span class="radio-button" />
              </label>
            </li>
          </ul>
        </template>

        <template v-if="$store.state.geoSelected.value === 'region'">
          <ul class="list">
            <li
              v-for="(region, index) in regions"
              :key="index"
            >
              <label class="radio-label">
                {{ region.previousName }} / {{ region.name }}
                <input
                  v-model="selectedZone"
                  :value="region.id"
                  type="radio"
                  class="list-checkbox"
                  @change="setZoneName(region.name, region.previousName)"
                >
                <span class="radio-button" />
              </label>
            </li>
          </ul>
        </template>

        <template v-if="$store.state.geoSelected.value === 'area'">
          <ul class="list">
            <li
              v-for="(area, index) in areas"
              :key="index"
            >
              <label class="radio-label">
                {{ area.previousName }} / {{ area.name }}
                <input
                  v-model="selectedZone"
                  :value="area.id"
                  type="radio"
                  class="list-checkbox"
                  @change="setZoneName(area.name)"
                >
                <span class="radio-button" />
              </label>
            </li>
          </ul>
        </template>

        <template v-if="$store.state.geoSelected.value === 'center'">
          <ul class="list">
            <li
              v-for="(center, index) in centers"
              :key="index"
            >
              <label class="radio-label">
                {{ center.name }}
                <input
                  v-model="selectedZone"
                  :value="center.id"
                  type="radio"
                  class="list-checkbox"
                  @change="setZoneName(center.name)"
                >
                <span class="radio-button" />
              </label>
            </li>
          </ul>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { platformMixin } from '@/platformMixin'

export default {
  mixins: [platformMixin],
  data() {
    return {
      selectedZone: null,
      selectedName: null,
      selectedPrevious: null,
      continents: [],
      countries: [],
      zones: [],
      regions: [],
      areas: [],
      centers: []
    }
  },
  computed: {
    previousName() {
      return this.$store.state.geoSelected.name
    },
    nextDisabled() {
      return !this.selectedZone
    }
  },
  mounted() {
    this.getGeoZones()
  },
  methods: {
    async getGeoZones() {
      // Obtenemos los tipos de sub-niveles de zona que hay
      const jsonTree = this.$store.state.geoResponse.geo.continent
      // Nivel: Continents
      jsonTree.forEach((continents) => {
        this.continents.push({
          id: continents['@attributes'].id,
          name: continents['@attributes'].name,
        })
      })
      // Nivel: Countries
      jsonTree.forEach((countries) => {
        const subCountries = countries.country
        const continentId = countries['@attributes'].id
        const continentName = countries['@attributes'].name
        if (subCountries.length > 1) {
          subCountries.forEach((multiple) => {
            this.countries.push({
              id: multiple['@attributes'].id,
              name: multiple['@attributes'].name,
              previousId: continentId,
              previousName: continentName,
              zones: multiple.zone,
            })
          })
        } else {
          this.countries.push({
            id: subCountries[0]['@attributes'].id,
            name: subCountries[0]['@attributes'].name,
            previousId: continentId,
            previousName: continentName,
            zones: subCountries[0].zone,
          })
        }
      })
      // Nivel: Zones
      this.countries.forEach((zones) => {
        const subZones = zones.zones
        const countryId = zones.id
        const countryName = zones.name
        if (subZones.length > 1) {
          subZones.forEach((multiple) => {
            this.zones.push({
              id: multiple['@attributes'].id,
              name: multiple['@attributes'].name,
              previousId: countryId,
              previousName: countryName,
              regions: multiple.region,
            })
          })
        } else {
          this.zones.push({
            id: subZones[0]['@attributes'].id,
            name: subZones[0]['@attributes'].name,
            previousId: countryId,
            previousName: countryName,
            regions: subZones[0].region,
          })
        }
      })
      // Nivel: Regions
      this.zones.forEach((regions) => {
        const subRegions = regions.regions
        const zoneId = regions.id
        const zoneName = regions.name
        if (subRegions.length > 1) {
          subRegions.forEach((multiple) => {
            this.regions.push({
              id: multiple['@attributes'].id,
              name: multiple['@attributes'].name,
              previousId: zoneId,
              previousName: zoneName,
              areas: multiple.area,
            })
          })
        } else {
          this.regions.push({
            id: subRegions[0]['@attributes'].id,
            name: subRegions[0]['@attributes'].name,
            previousId: zoneId,
            previousName: zoneName,
            areas: subRegions[0].area,
          })
        }
      })
      // Nivel: Areas
      this.regions.forEach((areas) => {
        const subAreas = areas.areas
        const regionId = areas.id
        const regionName = areas.name
        if (subAreas.length > 1) {
          subAreas.forEach((multiple) => {
            this.areas.push({
              id: multiple['@attributes'].id,
              name: multiple['@attributes'].name,
              previousId: regionId,
              previousName: regionName,
              centers: multiple.center,
            })
          })
        } else {
          this.areas.push({
            id: subAreas[0]['@attributes'].id,
            name: subAreas[0]['@attributes'].name,
            previousId: regionId,
            previousName: regionName,
            centers: subAreas[0].center,
          })
        }
      })
      // Nivel: Centers
      this.areas.forEach((centers) => {
        const subCenters = centers.centers
        const areaId = centers.id
        const areaName = centers.name
        if (subCenters.length > 1) {
          subCenters.forEach((multiple) => {
            this.centers.push({
              id: multiple['@attributes'].id,
              name: multiple['@attributes'].name,
              previousId: areaId,
              previousName: areaName,
              centers: multiple.center,
            })
          })
        } else {
          this.centers.push({
            id: subCenters[0]['@attributes'].id,
            name: subCenters[0]['@attributes'].name,
            previousId: areaId,
            previousName: areaName,
            centers: subCenters[0].center,
          })
        }
      })
      await this.$store.dispatch('setGeoLevels', {
        name: 'CONTINENT',
        data: this.continents
      })
      await this.$store.dispatch('setGeoLevels', {
        name: 'COUNTRY',
        data: this.countries
      })
      await this.$store.dispatch('setGeoLevels', {
        name: 'ZONE',
        data: this.zones
      })
      await this.$store.dispatch('setGeoLevels', {
        name: 'REGION',
        data: this.regions
      })
      await this.$store.dispatch('setGeoLevels', {
        name: 'AREA',
        data: this.areas
      })
      await this.$store.dispatch('setGeoLevels', {
        name: 'CENTER',
        data: this.centers
      })
    },
    async setGeoZone() {
      if (this.$store.state.geoSelected.value === 'center') {
        const centerData = {ID: this.selectedZone, Name: this.selectedName}
        const geoData = {ID: '', Name: null}
        await this.$store.dispatch('setCenterData', centerData)
        await this.$store.dispatch('setGeoData', geoData)
      } else {
        const geoData = {ID: this.selectedZone, Name: this.selectedName}
        const centerData = {ID: '', Name: null}
        await this.$store.dispatch('setCenterData', centerData)
        await this.$store.dispatch('setGeoData', geoData)
      }

      // Guardamos los hermanos para navegar en Dashboard
      let geoBrothers = []
      switch (this.$store.state.geoSelected.value) {
        case 'continent':
          Object.keys(this.continents).forEach((val) => {
            geoBrothers.push({
              id: this.continents[val].id,
              name: this.continents[val].name,
            })
          })
          break
        case 'country':
          Object.keys(this.countries).forEach((val) => {
            if (this.countries[val].previousName === this.selectedPrevious) {
              geoBrothers.push({
                id: this.countries[val].id,
                name: this.countries[val].name,
              })
            }
          })
          break
        case 'zone':
          Object.keys(this.zones).forEach((val) => {
            if (this.zones[val].previousName === this.selectedPrevious) {
              geoBrothers.push({
                id: this.zones[val].id,
                name: this.zones[val].name,
              })
            }
          })
          break
        case 'region':
          Object.keys(this.regions).forEach((val) => {
            if (this.regions[val].previousName === this.selectedPrevious) {
              geoBrothers.push({
                id: this.regions[val].id,
                name: this.regions[val].name,
              })
            }
          })
          break
        case 'area':
          Object.keys(this.areas).forEach((val) => {
            if (this.areas[val].previousName === this.selectedPrevious) {
              geoBrothers.push({
                id: this.areas[val].id,
                name: this.areas[val].name,
              })
            }
          })
          break
      }
      await this.$store.dispatch('setGeoBrothers', geoBrothers)
      // Avanzamos
      this.$router.push({name: 'ConfigCampaign'})
    },
    setZoneName(name, previous) {
      this.selectedName = name
      this.selectedPrevious = previous
    }
  }
}
</script>
