import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import ConfigIntro from '@/components/config/ConfigIntro'
import ConfigGeoHierarchy from '@/components/config/ConfigGeoHierarchy'
import ConfigGeoZone from '@/components/config/ConfigGeoZone'
import ConfigGeoWaves from '@/components/config/ConfigGeoWaves'
import ConfigCampaign from '@/components/config/ConfigCampaign'
import Dashboard from '@/components/Dashboard'
import DashboardDetail from '@/components/DashboardDetail'
import DashboardConfig from '@/components/DashboardConfig'
import DashboardMotives from '@/components/DashboardMotives'
import DashboardComments from '@/components/DashboardComments'
import DashboardCampaigns from '@/components/DashboardCampaigns'

Vue.use(Router)

export default new Router({
  /*mode: 'history',*/
  routes: [
    {
      path: '/',
      component: Login,
      name: 'Login',
      meta: { transitionName: 'slide-down-home' }
    },
    {
      path: '/config',
      component: ConfigIntro,
      name: 'ConfigIntro',
      meta: { transitionName: 'slide' }
    },
    {
      path: '/config/geohierarchy',
      component: ConfigGeoHierarchy,
      name: 'ConfigGeoHierarchy',
      meta: { transitionName: 'slide' }
    },
    {
      path: '/config/geohierarchy/geozone',
      component: ConfigGeoZone,
      name: 'ConfigGeoZone',
      meta: { transitionName: 'slide' }
    },
    {
      path: '/config/geohierarchy/geozone/geowaves',
      component: ConfigGeoWaves,
      name: 'ConfigGeoWaves',
      meta: { transitionName: 'slide' }
    },
    {
      path: '/config/geohierarchy/geozone/configcampaign',
      component: ConfigCampaign,
      name: 'ConfigCampaign',
      meta: { transitionName: 'slide' }
    },
    {
      path: '/Dashboard',
      component: Dashboard,
      name: 'Dashboard',
      meta: { transitionName: 'slide-down' },
      children: [
        {
          path: ':hierarchy/:id',
          component: DashboardDetail,
          name: 'npsView'
        },
      ],
    },
    {
      path: '/DashboardConfig',
      component: DashboardConfig,
      name: 'DashboardConfig',
      meta: { transitionName: 'zoom' }
    },
    {
      path: '/DashboardMotives',
      component: DashboardMotives,
      name: 'DashboardMotives',
      meta: { transitionName: 'zoom' }
    },
    {
      path: '/DashboardComments',
      component: DashboardComments,
      name: 'DashboardComments',
      meta: { transitionName: 'zoom' }
    },
    {
      path: '/DashboardCampaigns',
      component: DashboardCampaigns,
      name: 'DashboardCampaigns',
      meta: { transitionName: 'zoom' }
    }
  ]
})
