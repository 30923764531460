<template>
  <div class="container">
    <div
      class="grid justify-center"
      style="height:100%"
    >
      <div class="col-10 col-grid m-t-2">
        <form @submit.prevent="doLogin">
          <img
            src="@/assets/images/title.png"
            srcset="@/assets/images/title@2x.png 2x, @/assets/images/title@3x.png 3x"
            class="logo"
          >
          <div class="form-group">
            <label for="user">Usuario</label>
            <input
              id="user"
              v-model="creds.user"
              type="text"
              class="form-control"
              placeholder="Introduzca su ID de usuario"
              autocorrect="off"
              autocapitalize="none"
            >
          </div>
          <div class="form-group">
            <label for="pass">Clave</label>
            <input
              id="pass"
              v-model="creds.pass"
              type="password"
              class="form-control"
              placeholder="Introduzca su contraseña"
              @focusout="fullscreen"
            >
          </div>
          <div class="grid">
            <div class="col-12">
              <label for="switch">Servidor de acceso</label><br>
              <span class="text-muted">Europa</span>
              <span class="onoffswitch-login m-x-1">
                <input
                  id="switch"
                  v-model="loginServer"
                  type="checkbox"
                  class="onoffswitch-checkbox-login"
                >
                <label
                  class="onoffswitch-label-login"
                  for="switch"
                />
              </span>
              <span class="text-muted">Sudamérica</span>
            </div>
          </div>
          <div class="grid">
            <div class="col-10 col-bleed-x">
              <div
                v-if="errorApi"
                class="alert"
              >
                Credenciales erróneas
              </div>
              <div
                v-if="errorInput"
                class="alert"
              >
                Campos obligatorios
              </div>
            </div>
            <div class="col-2 col-bleed-x">
              <button
                class="btn"
                type="submit"
              >
                <img
                  src="@/assets/images/ico_next_off.png"
                  srcset="@/assets/images/ico_next_off@2x.png 2x, @/assets/images/ico_next_off@3x.png 3x"
                >
              </button>
            </div>
          </div>
        </form>
        <div
          class="text-center"
          style="position:fixed;bottom:30px;left:0;width:100%"
        >
          <!-- <a
            href="https://www.opinat.com"
          >¿Cómo funciona el sistema NPS?</a> -->
          <!-- <a
            href="https://www.opinat.com"
            @click.prevent="goToWeb"
          >¿Cómo funciona el sistema NPS?</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { loginMixin } from '@/loginMixin'

  export default {
    mixins: [loginMixin],
    data() {
      return {
        errorApi: false,
        errorInput: false,
        creds: {
          user: null,
          pass: null
        }
      }
    },
    computed: {
      loginServer: {
        get() {
          return this.$store.state.loginServer
        },
        set(value) {
          this.$store.commit('SET_LOGIN_SERVER', value)
        }
      }
    },
    mounted () {
      this.$store.dispatch('setVersion', '1.0-wa')
      // Login the user if has saved login data
      if (this.$store.state.login.user && this.$store.state.login.pass && this.$store.state.login.token) {
        this.creds.user = this.$store.state.login.user;
        this.creds.pass = this.$store.state.login.pass;
        this.doLogin();
      }
    },
    methods: {
      async doLogin() {
        if (!this.creds.user || !this.creds.pass) {
          this.errorInput = true
        } else {
          this.$Progress.start()
          try {
            await this.npsenseLogin(this.creds.user, this.creds.pass);
            this.$Progress.finish()
            // Asignamos Custom ID Batch para notificaciones directas a usuarios
            // batch.user.getEditor().setIdentifier(this.$store.state.login.user).save()
            if (this.$store.state.login.user && this.$store.state.login.pass && this.$store.state.login.token) {
              if (this.$store.state.currentHierarchy) 
              {
                this.$router.push({name: 'Dashboard'})
              } else {
                this.$router.push({name: 'ConfigIntro'})
              }
            }
          } catch (error) {
              this.$Progress.fail()
              console.log(error)
              this.errorApi = true
          }
        }
      },
      // goToWeb() {
      //   window.open('https://www.opinat.com', '_system');
      // },
      fullscreen() {
        // if (device.platform === 'Android') {
        //   window.AndroidFullScreen.immersiveMode()
        // }
      }
    }
  }
</script>
