<template>
  <div class="dashboard-comp">
    <div class="topbar" :class="platform">
      <div class="close">
        <a href="#" class="btn btn-link btn-close" @click.prevent="hideModal">&times;</a>
      </div>
      <div class="titleCampaign">
        {{ this.$store.state.currentName }}
        <p class="subtitle">
          {{ campaignName }}
        </p>
      </div>
    </div>

    <div class="fondoAzul dashboard-popup-with-bottom" :class="platform">
      <div v-if="comments.length >= 1" key="comments" :class="{ commentsinfo: dashboardCommentinfo }">
        <v-touch v-for="(comment, index) in comments" :key="index" class="comment" @swiperight="openOptionsLeft"
          @swipeleft="openOptionsRight" @tap="closeOptions">
          <div class="frontOptions">
            <div class="container">
              <div class="grid">
                <div class="col-12 col-bleed-x">
                  <div class="ico">
                    <img v-if="comment.state === 'Promoter'" src="@/assets/images/bigico_npsTable_positive.png"
                      srcset="@/assets/images/bigico_npsTable_positive@2x.png 2x, @/assets/images/bigico_npsTable_positive@3x.png 3x"
                      width="16">
                    <img v-if="comment.state === 'Passive'" src="@/assets/images/bigico_npsTable_neutral.png"
                      srcset="@/assets/images/bigico_npsTable_neutral@2x.png 2x, @/assets/images/bigico_npsTable_neutral@3x.png 3x"
                      width="16">
                    <img v-if="comment.state === 'Detractor'" src="@/assets/images/bigico_npsTable_negative.png"
                      srcset="@/assets/images/bigico_npsTable_negative@2x.png 2x, @/assets/images/bigico_npsTable_negative@3x.png 3x"
                      width="16">
                  </div>
                  <div class="info">
                    {{ comment.token_name }}<br>
                    <span class="fadeout">
                      {{ comment.date }}<br>
                      {{ comment.wave['@attributes'].name }}<br>
                      {{ comment.center['@attributes'].name }}
                    </span>
                  </div>
                  <div v-if="parseInt(comment.nps) > 8" key="promoter" class="badge green">
                    {{ comment.nps }}
                  </div>
                  <div v-else-if="parseInt(comment.nps) < 7" key="detractor" class="badge red">
                    {{ comment.nps }}
                  </div>
                  <div v-else key="passive" class="badge yellow">
                    {{ comment.nps }}
                  </div>
                  <div class="message">
                    {{ comment.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="backOptionsLeft">
            <div class="options">
              <a class="promoter" @click="rateComment(comment, 'Promoter')">
                <img src="@/assets/images/bigico_npsTable_positive@2x.png"
                  srcset="@/assets/images/bigico_npsTable_positive@3x.png 2x" width="24">
                Positivo
              </a>
              <a class="passive" @click="rateComment(comment, 'Passive')">
                <img src="@/assets/images/bigico_npsTable_neutral@2x.png"
                  srcset="@/assets/images/bigico_npsTable_neutral@3x.png 2x" width="24">
                Pasivo
              </a>
              <a class="detractor" @click="rateComment(comment, 'Detractor')">
                <img src="@/assets/images/bigico_npsTable_negative@2x.png"
                  srcset="@/assets/images/bigico_npsTable_negative@3x.png 2x" width="24">
                Negativo
              </a>
            </div>
          </div>
          <div class="backOptionsRight">
            <div class="options">
              <a href="#" @click.prevent="viewCommentInfo(index)">
                <img src="@/assets/images/ico_table_comments.png"
                  srcset="@/assets/images/ico_table_comments@2x.png 2x, @/assets/images/ico_table_comments@3x.png 3x"
                  width="20"><br>
                + Información
              </a>
            </div>
          </div>
        </v-touch>
      </div>

      <div v-else-if="errorApi || comments.length === 0" key="error" class="text-center">
        <div class="spacer rem2" />
        <div class="spacer rem2" />
        <p class="alert">
          Lo sentimos, no hay datos asociados a la configuración especificada.
        </p>
      </div>
      <div v-else key="loading" class="text-center">
        <div class="spacer rem2" />
        <img src="@/assets/images/loading.gif" width="21" alt="">
      </div>
    </div>

    <div class="bottombar">
      <div class="container">
        <div class="grid grid-bleed">
          <div class="col-12 text-center motiveButtons">
            <button class="btn btn-icon btn-type" :class="commentType === 'Promoter' ? 'active' : ''"
              @click="changeCommentType('Promoter')">
              <img src="@/assets/images/bigico_npsTable_positive.png" width="22"
                srcset="@/assets/images/bigico_npsTable_positive@2x.png 2x, @/assets/images/bigico_npsTable_positive@3x.png 3x">
            </button>
            &nbsp;
            <button class="btn btn-icon btn-type" :class="commentType === 'Passive' ? 'active' : ''"
              @click="changeCommentType('Passive')">
              <img src="@/assets/images/bigico_npsTable_neutral.png" width="22"
                srcset="@/assets/images/bigico_npsTable_neutral@2x.png 2x, @/assets/images/bigico_npsTable_neutral@3x.png 3x">
            </button>
            &nbsp;
            <button class="btn btn-icon btn-type" :class="commentType === 'Detractor' ? 'active' : ''"
              @click="changeCommentType('Detractor')">
              <img src="@/assets/images/bigico_npsTable_negative.png" width="22"
                srcset="@/assets/images/bigico_npsTable_negative@2x.png 2x, @/assets/images/bigico_npsTable_negative@3x.png 3x">
            </button>
          </div>
        </div>
      </div>
    </div>

    <transition name="slide-up">
      <template v-if="dashboardCommentinfo">
        <dashboard-commentinfo :dashboard-commentinfo="dashboardCommentinfo" :campaign-name="dynamicCampaignName"
          :center-name="centerName" :user-name="userName" :comment-date="commentDate" :comment-wave="commentWave"
          :internal-code="internalCode" :phone-number="phoneNumber" :email-address="emailAddress"
          :internal-data-field="internalDataField" :field1="field1" :field2="field2" :field3="field3" :field4="field4"
          :field5="field5" :field6="field6" :field7="field7" :field8="field8" :field9="field9" :field10="field10"
          :comment-nps="commentNps" @dashboard-commentinfo-opened="dashboardCommentinfo = $event" />
      </template>
    </transition>
  </div>
</template>

<script>
import DashboardCommentinfo from './DashboardCommentinfo'
import { platformMixin } from '@/platformMixin'
import { loginMixin } from '@/loginMixin'

export default {
  components: {
    DashboardCommentinfo,
  },
  mixins: [platformMixin, loginMixin],
  props: {
    dynamicCampaignId: {
      type: Number,
      required: true
    },
    dynamicCampaignName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dashboardCommentinfo: false,
      errorApi: false,
      geoName: this.$store.state.geoData.Name,
      commentType: 'Promoter',
      comments: false,
      // Comment Info data
      userName: '',
      commentDate: '',
      commentWave: '',
      centerName: '',
      internalCode: '',
      phoneNumber: '',
      emailAddress: '',
      internalDataField: '',
      field1: '',
      field2: '',
      field3: '',
      field4: '',
      field5: '',
      field6: '',
      field7: '',
      field8: '',
      field9: '',
      field10: '',
      commentNps: ''
    }
  },
  computed: {
    campaignName() {
      let name
      if (this.dynamicCampaignName === '') {
        name = this.$store.state.campaignData.Name
      } else {
        name = this.dynamicCampaignName
      }
      return name
    },
  },
  created() {
    this.npsenseLogin(this.$store.state.login.user, this.$store.state.login.pass)
      .then(() => {
        setTimeout(() => {
          this.setCommentData(this.dynamicCampaignId)
        }, 100)
      })
  },
  methods: {
    hideModal() {
      this.$emit('dashboard-comments-opened', false)
    },
    openOptionsLeft(e) {
      this.closeOptions()
      const el = e.target.closest('.comment').querySelector('div.frontOptions')
      el.classList.add('open-right')
    },
    openOptionsRight(e) {
      this.closeOptions()
      const el = e.target.closest('.comment').querySelector('div.frontOptions')
      el.classList.add('open-left')
    },
    closeOptions() {
      const els = document.getElementsByClassName('open-left')
      while (els[0]) {
        els[0].classList.remove('open-left')
      }
      const els2 = document.getElementsByClassName('open-right')
      while (els2[0]) {
        els2[0].classList.remove('open-right')
      }
    },
    changeCommentType(type) {
      this.comments = false
      this.commentType = type
      this.setCommentData(this.dynamicCampaignId)
      window.scrollTo(0, 1000)
    },
    setCommentData(campaignIDprop) {
      this.$Progress.start()
      // Comprobamos qué ha seleccionado el usuario para hacer la consulta
      let campaignID = campaignIDprop === '' ? this.$store.state.campaignData.ID : campaignIDprop;
      let geoID = this.$store.state.currentID
      let centerID = this.$store.state.centerData.ID
      let waveID = ''
      if (this.$store.state.waveData.ID) {
        geoID = ''
        centerID = ''
        campaignID = ''
        waveID = this.$store.state.waveData.ID
      }
      if (centerID) {
        geoID = ''
      }
      if (this.$store.state.currentHierarchy === 'center' && !this.$store.state.waveData.ID) {
        geoID = ''
        centerID = this.$store.state.currentID
      }
      const dataRequest = '<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body>' +
        '<apiGetCommentsByType>' +
        '<sessionkey>' + this.$store.state.login.token + '</sessionkey>' +
        '<geo_id>' + geoID + '</geo_id>' +
        '<center_id>' + centerID + '</center_id>' +
        '<campaign_id>' + campaignID + '</campaign_id>' +
        '<wave_id>' + waveID + '</wave_id>' +
        '<comment_type>' + this.commentType + '</comment_type>' +
        '<answer_type>json</answer_type>' +
        '</apiGetCommentsByType></soap:Body></soap:Envelope>'
      const _app = this

      fetch(this.npsenseApiUrl, {
        method: 'POST',
        headers: this.npsenseApiDataHeaders,
        body: dataRequest
      })
        .then(response => response.text())
        .then(data => {
          const parser = new DOMParser()
          const result = parser.parseFromString(data, 'text/xml')
          const commentsResponse = result.getElementsByTagName('ns1:apiGetCommentsByTypeResponse')[0].textContent
          const tempComments = JSON.parse(commentsResponse)
          _app.comments = tempComments.length === 0 ? [] : tempComments.comment
          _app.$Progress.finish()
        })
        .catch(error => {
          _app.$Progress.fail()
          console.error('Error:', error) // Aquí puedes manejar el error de la manera que prefieras
          _app.hideModal()
        });
    },
    rateComment(comment, score) {
      this.$Progress.start()
      const dataRequest = '<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body>' +
        '<apiRateComment>' +
        '<sessionkey>' + this.$store.state.login.token + '</sessionkey>' +
        '<token_id>' + comment.token_id + '</token_id>' +
        '<comment_score>' + score + '</comment_score>' +
        '<answer_type>json</answer_type>' +
        '</apiRateComment></soap:Body></soap:Envelope>'
      const _app = this

      fetch(this.npsenseApiUrl, {
        method: 'POST',
        headers: this.npsenseApiDataHeaders,
        body: dataRequest
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then(() => {
          _app.setCommentData(_app.dynamicCampaignId)
          _app.$Progress.finish()
        })
        .catch(error => {
          _app.$Progress.fail()
          console.error('Error:', error)
          _app.$router.push('/')
        });
    },

    viewCommentInfo(index) {
      this.userName = this.comments[index].token_name
      this.commentDate = this.comments[index].date
      this.commentWave = this.comments[index].wave['@attributes'].name
      this.centerName = this.comments[index].center['@attributes'].name
      this.internalCode = this.comments[index].token_internal_code
      this.phoneNumber = this.comments[index].token_phone
      this.emailAddress = this.comments[index].token_email
      this.internalDataField = this.comments[index].token_internal_data_field
      this.field1 = this.comments[index].token_field01
      this.field2 = this.comments[index].token_field02
      this.field3 = this.comments[index].token_field03
      this.field4 = this.comments[index].token_field04
      this.field5 = this.comments[index].token_field05
      this.field6 = this.comments[index].token_field06
      this.field7 = this.comments[index].token_field07
      this.field8 = this.comments[index].token_field08
      this.field9 = this.comments[index].token_field09
      this.field10 = this.comments[index].token_field10
      this.commentNps = this.comments[index].nps
      this.dashboardCommentinfo = true
    }
  }
}
</script>
