<template>
  <div
    id="app"
    :class="`platform-${platform}`"
  >
    <transition-page>
      <router-view />
    </transition-page>
    <vue-progress-bar />
  </div>
</template>

<script>
  import TransitionPage from '@/components/TransitionPage'
  import { platformMixin } from '@/platformMixin'

  export default {
    name: 'App',
    components: {
      TransitionPage
    },
    mixins: [platformMixin]
  }
</script>

<style src="./less/styles.less" lang="less"></style>
