<template>
  <div class="dashboard-comp">

    <div class="topbar" :class="platform">
      <div class="close">
        <a href="#" class="btn btn-link btn-close" @click.prevent="hideModal">&times;</a>
      </div>
      <div class="titleCampaign">{{ this.$store.state.currentName }} <p class="subtitle">{{ subtitle }}</p>
      </div>
    </div>

    <div class="fondoAzul dashboard-popup-with-bottom" :class="platform">

      <div v-if="npsData" key="npsdata" class="tab">
        <div class="data text-center" v-for="(nps, index) of npsData" :key="index">
          <div v-if="nps" class="container">
            <div class="grid">
              <div class="col-12 col-bleed-y">
                <div class="zone">{{ nps.month | monthName }} {{ nps.year }}</div>
              </div>
            </div>
            <div class="grid">
              <div class="col-3" style="padding:0">
                <div class="nps1">
                  <div class="nps1a">{{ nps.surveys }}</div>
                  <div class="nps1b">{{ nps.answers }}</div>
                </div>
              </div>
              <div class="col-6 col-bleed-x align-self-center" style="padding:0">
                <div class="nps2">
                  {{ nps.nps | addDecimal }}<span class="percentage">%</span>
                </div>
              </div>
              <div class="col-3" style="padding:0">
                <div class="nps3">
                  <div class="circleProgress">
                    <radial-progress-bar :completed-steps="parseInt(nps.answerrate)">
                      <span>{{ parseInt(nps.answerrate) | addPercent }}</span>
                    </radial-progress-bar>
                  </div>
                </div>
              </div>
            </div>
            <div @click="toggleValue" class="grid grid-bleed sat">
              <div class="col-12">
                <div class="sat1">
                  <img src="@/assets/images/bigico_npsTable_positive.png"
                    srcset="@/assets/images/bigico_npsTable_positive@2x.png 2x, @/assets/images/bigico_npsTable_positive@3x.png 3x"
                    width="16">
                  <transition name="fadefast" mode="out-in">
                    <span v-if="valuePercent" key="valuePercent">{{
                      nps.promoters_pctg | addDecimal | addPercent
                    }}</span>
                    <span v-else key="valueTotal">{{ nps.promoters }}</span>
                  </transition>
                </div>
                <div class="sat2">
                  <img src="@/assets/images/bigico_npsTable_neutral.png"
                    srcset="@/assets/images/bigico_npsTable_neutral@2x.png 2x, @/assets/images/bigico_npsTable_neutral@3x.png 3x"
                    width="16">
                  <transition name="fadefast" mode="out-in">
                    <span v-if="valuePercent" key="valuePercent">{{
                      nps.passives_pctg | addDecimal | addPercent
                    }}</span>
                    <span v-else key="valueTotal">{{ nps.passives }}</span>
                  </transition>
                </div>
                <div class="sat3">
                  <img src="@/assets/images/bigico_npsTable_negative.png"
                    srcset="@/assets/images/bigico_npsTable_negative@2x.png 2x, @/assets/images/bigico_npsTable_negative@3x.png 3x"
                    width="16">
                  <transition name="fadefast" mode="out-in">
                    <span v-if="valuePercent" key="valuePercent">{{
                      nps.detractors_pctg | addDecimal | addPercent
                    }}</span>
                    <span v-else key="valueTotal">{{ nps.detractors }}</span>
                  </transition>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="text-center" v-else key="loading">
        <div class="spacer rem2"></div>
        <img src="@/assets/images/loading.gif" width="21" alt="">
      </div>

    </div>

    <div class="bottombar">
      <div class="container">
        <div class="grid grid-bleed">
          <div class="col-12 text-center motiveButtons">
            <button @click="changeCalendarView('Months')" class="btn btn-icon btn-type"
              :class="calendarView === 'Months' ? 'active' : ''">
              Meses
            </button>
            &nbsp;
            <button @click="changeCalendarView('Years')" class="btn btn-icon btn-type"
              :class="calendarView === 'Years' ? 'active' : ''">
              Años
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import { loginMixin } from '../loginMixin'
import { platformMixin } from '../platformMixin'
import RadialProgressBar from './RadialProgress'

export default {
  filters: {
    translateName(value) {
      let nombre = value
      switch (nombre) {
        case 'country':
          nombre = 'País'
          break
        case 'zone':
          nombre = 'Zona'
          break
        case 'region':
          nombre = 'Región'
          break
        case 'area':
          nombre = 'Área'
          break
        case 'center':
          nombre = 'Centro'
          break
        default:
          nombre = 'Continente'
          break
      }
      return nombre
    },
    addDecimal(value) {
      const valor = parseFloat(value)
      return valor.toFixed(2)
    },
    addPercent(value) {
      return `${value}%`
    },
    monthName(value) {
      let monthName
      switch (value) {
        case 12:
          monthName = 'Diciembre'
          break
        case 11:
          monthName = 'Noviembre'
          break
        case 10:
          monthName = 'Octubre'
          break
        case 9:
          monthName = 'Septiembre'
          break
        case 8:
          monthName = 'Agosto'
          break
        case 7:
          monthName = 'Julio'
          break
        case 6:
          monthName = 'Junio'
          break
        case 5:
          monthName = 'Mayo'
          break
        case 4:
          monthName = 'Abril'
          break
        case 3:
          monthName = 'Marzo'
          break
        case 2:
          monthName = 'Febrero'
          break
        case 1:
          monthName = 'Enero'
          break
      }
      return monthName
    }
  },
  components: {
    RadialProgressBar
  },
  mixins: [loginMixin, platformMixin],
  props: ['currentID', 'dynamicCampaignName'],
  data() {
    return {
      valuePercent: true,
      hierarchy: this.$store.state.currentHierarchy,
      calendarView: 'Months',
      npsData: null
    }
  },
  computed: {
    loginData() {
      return this.$store.state.login.token
    },
    subtitle() {
      return (this.calendarView === 'Months') ? 'Últimos 16 meses' : 'Últimos 3 años'
    }
  },
  created() {
    this.npsenseLogin(this.$store.state.login.user, this.$store.state.login.pass)
      .then(() => {
        setTimeout(() => {
          this.getNPSData()
        }, 100)
      })
  },
  methods: {
    hideModal() {
      this.$emit('dashboard-calendarview-opened', false)
    },
    async getNPSData() {
      this.$Progress.start();
      // Comprobamos qué ha seleccionado el usuario para hacer la consulta
      let geoID;
      let centerID;
      let campaignID;
      let waveID = '';
      if (this.$store.state.waveData.ID) {
        geoID = '';
        centerID = '';
        campaignID = '';
        waveID = this.$store.state.waveData.ID;
      } else {
        geoID = this.$store.state.currentID;
        centerID = this.$store.state.centerData.ID;
        campaignID = this.$store.state.campaignData.ID;
      }
      if (this.hierarchy === 'center' && !this.$store.state.waveData.ID) {
        geoID = '';
        centerID = this.$store.state.currentID;
      }
      // Hacemos la consulta
      const apiCall = (this.calendarView === 'Months') ? 'apiGetNPSDataByMonth' : 'apiGetNPSDataByYear';
      const dataRequest = '<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body>' +
        '<' + apiCall + '>' +
        '<sessionkey>' + this.$store.state.login.token + '</sessionkey>' +
        '<geo_id>' + geoID + '</geo_id>' +
        '<center_id>' + centerID + '</center_id>' +
        '<campaign_id>' + campaignID + '</campaign_id>' +
        '<wave_id>' + waveID + '</wave_id>' +
        '<answer_type>json</answer_type>' +
        '</' + apiCall + '></soap:Body></soap:Envelope>';

      try {
        const response = await fetch(this.npsenseApiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/xml',
            ...this.npsenseApiDataHeaders
          },
          body: dataRequest
        });

        if (!response.ok) {
          throw new Error('Error en la respuesta del servidor');
        }

        const responseData = await response.text();
        const parser = new DOMParser();
        const result = parser.parseFromString(responseData, 'text/xml');
        const npsResponse = JSON.parse(result.getElementsByTagName('ns1:' + apiCall + 'Response')[0].textContent);

        this.formatNPSdata(npsResponse);
        this.$Progress.finish();
      } catch (error) {
        console.error('Error en getNPSData:', error);
        this.$Progress.fail();
        this.$router.push('/');
      }
    },
    formatNPSdata(data) {
      let dataArray = []
      const iterations = (this.calendarView === 'Months') ? 16 : 3
      for (let i = 0; i < iterations; i++) {
        if (data[`nps_${i}`]) {
          dataArray.push({
            nps: data[`nps_${i}`],
            month: data[`month_${i}`],
            year: data[`year_${i}`],
            surveys: data[`surveys_${i}`],
            answers: data[`answers_${i}`],
            answerrate: data[`answerrate_${i}`],
            detractors: data[`detractors_${i}`],
            detractors_pctg: data[`detractors_pctg_${i}`],
            passives: data[`passives_${i}`],
            passives_pctg: data[`passives_pctg_${i}`],
            promoters: data[`promoters_${i}`],
            promoters_pctg: data[`promoters_pctg_${i}`],
          })
        }
      }
      this.npsData = dataArray
    },
    toggleValue() {
      this.valuePercent = !this.valuePercent
    },
    changeCalendarView(type) {
      this.calendarView = type
      this.npsData = null
      this.npsenseLogin(this.$store.state.login.user, this.$store.state.login.pass)
        .then(() => {
          setTimeout(() => {
            this.getNPSData()
          }, 100)
        })
    }
  }
}
</script>
