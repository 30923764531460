<template>
  <transition name="fade">
    <router-view />
  </transition>
</template>

<script>
import { loginMixin } from '@/loginMixin'

export default {
  mixins: [loginMixin],
  mounted() {
    this.npsenseLogin(this.$store.state.login.user, this.$store.state.login.pass)
      .then(() => {
        if (this.$store.state.geoSelected.value === 'center') {
          this.$router.push(`/Dashboard/center/${this.$store.state.centerData.ID}`)
        } else {
          this.$router.push(`/Dashboard/${this.$store.state.geoSelected.value}/${this.$store.state.geoData.ID}`)
        }
      })
  }
}
</script>
