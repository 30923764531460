<template>
  <div class="dashboard-comp">
    <div class="topbar" :class="platform">
      <div class="container">
        <div class="grid grid-bleed">
          <div class="col-12">
            <a href="#" class="btn btn-link btn-close" @click.prevent="hideModal">&times;</a>
          </div>
        </div>
      </div>
    </div>

    <div class="fondoAzul dashboard-popup" :class="platform">
      <div class="dashboard-config">
        <div class="dashboard-config-link no-top-border blanco">
          <div class="container">
            <div class="grid grid-bleed">
              <div class="col-12">
                <button class="btn btn-link btn-full blanco" @click="initConfig()">
                  Iniciar configuración <span class="pull-right">
                    <img src="@/assets/images/arrow-right-white.png" width="13"
                      srcset="@/assets/images/arrow-right-white@2x.png 2x, @/assets/images/arrow-right-white@3x.png 3x">
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="grid">
            <div class="col-12 col-bleed-x">
              <p>Vuelve a re-configurar la aplicación para monitorizar valores distintos a los actuales</p>
            </div>
          </div>
        </div>

        <!-- <div class="dashboard-config-link no-bottom-border blanco">
          <div class="container">
            <div class="grid grid-bleed">
              <div class="col-12">
                <button
                  class="btn btn-link btn-full blanco"
                  @click="initPush()"
                >
                  Configurar notificaciones <span class="pull-right">
                    <img
                      src="@/assets/images/arrow-right-white.png"
                      width="13"
                      srcset="@/assets/images/arrow-right-white@2x.png 2x, @/assets/images/arrow-right-white@3x.png 3x"
                    >
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div> -->

        <div class="dashboard-config-link blanco">
          <div class="container">
            <div class="grid grid-bleed">
              <div class="col-12">
                <div>
                  <span class="btn btn-link blanco">Mantener conectado</span>
                  <span class="onoffswitch pull-right">
                    <input id="switch" v-model="keepConnected" type="checkbox" name="compartir"
                      class="onoffswitch-checkbox">
                    <label class="onoffswitch-label" for="switch" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="grid">
            <div class="col-12 col-bleed-x">
              <p>
                Al desactivar esta opción tendrás que introducir tus credenciales siempre que inicies la aplicación.
              </p>
            </div>
          </div>
        </div>

        <div class="dashboard-config-link no-bottom-border">
          <div class="container">
            <div class="grid grid-bleed">
              <div class="col-12">
                <a href="#" class="btn btn-link" @click.prevent="sendEmail()">Reportar un problema</a>
              </div>
            </div>
          </div>
        </div>

        <div class="dashboard-config-link">
          <div class="container">
            <div class="grid grid-bleed">
              <div class="col-12">
                <a href="#" class="btn btn-link" @click.prevent="logout()">Cerrar sesión</a>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="grid">
            <div class="col-12 col-bleed-x">
              <p>v {{ version }}</p>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="grid grid-bleed">
            <div class="col-12 text-center">
              <img src="@/assets/images/logo_opinat.png" width="250"
                srcset="@/assets/images/logo_opinat@2x.png 2x, @/assets/images/logo_opinat@3x.png 3x">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginMixin } from '@/loginMixin'
import { platformMixin } from '@/platformMixin'

export default {
  mixins: [loginMixin, platformMixin],
  props: {
    dashboardConfig: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      keepConnected: true,
      callback: ''
    }
  },
  computed: {
    loginData() {
      return this.$store.state.login.token
    },
    version() {
      return this.$store.state.version
    }
  },
  watch: {
    loginData() {
      if (this.$store.state.login.token) {
        this.$router.push(this.callback)
      }
    },
    keepConnected() {
      if (this.keepConnected === false) {
        this.$store.dispatch('clearLoginData')
      }
    }
  },
  methods: {
    hideModal() {
      this.$emit('dashboard-opened', false)
    },
    initConfig() {
      this.$parent.clearSwiper()
      this.$store.dispatch('clearNPSenseData')
      this.npsenseLogin(this.$store.state.login.user, this.$store.state.login.pass)
        .then(() => {
          this.$router.push({ name: 'ConfigGeoHierarchy' })
        })
    },
    initPush() {
      this.$parent.clearSwiper()
      this.npsenseLogin(this.$store.state.login.user, this.$store.state.login.pass)
        .then(() => {
          this.$router.push({ name: 'Dashboard' })
        })
    },
    logout() {
      this.$parent.clearSwiper()
      this.$store.dispatch('clearLoginData')
      this.$store.dispatch('clearNPSenseData')
      this.$router.push('/')
    },
    sendEmail() {
      const email = 'supportnpsense@opinat.com';
      const subject = `NPSense v${this.version}`;
      window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    }
  }
}
</script>
