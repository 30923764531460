<template>
  <div class="dashboard-comp">
    <div
      class="topbar"
      :class="platform"
    >
      <div class="close">
        <a
          href="#"
          class="btn btn-link btn-close"
          @click.prevent="hideModal"
        >
          &times;
        </a>
      </div>
      <div class="titleCampaign">
        {{ hierarchy | translateName }}
        <p class="subtitle">
          {{ campaignName }}
        </p>
      </div>
    </div>

    <div
      class="fondoAzul dashboard-popup"
      :class="platform"
    >
      <div
        v-for="brother in brothers"
        :key="brother.id"
        class="tab"
      >
        <div class="data text-center">
          <div class="container">
            <div class="grid">
              <div class="col-12 col-bleed-y">
                <div class="zone">
                  {{ brother.name }}
                </div>
              </div>
            </div>

            <div class="grid">
              <div
                class="col-3"
                style="padding:0"
              >
                <div class="nps1">
                  <div class="nps1a">
                    {{ brother.totalSurveys }}
                  </div>
                  <div class="nps1b">
                    {{ brother.totalAnswers }}
                  </div>
                </div>
              </div>
              <div class="col-6 align-self-center">
                <div class="nps2">
                  {{ brother.totalNps | addDecimal }}
                  <span class="percentage">%</span>
                </div>
              </div>
              <div
                class="col-3"
                style="padding:0"
              >
                <div class="nps3">
                  <div class="circleProgress">
                    <radial-progress-bar
                      :completed-steps="parseInt(brother.totalAnswerRate)"
                    >
                      <span>{{ parseInt(brother.totalAnswerRate) | addPercent }}</span>
                    </radial-progress-bar>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="grid grid-bleed sat"
              @click="toggleValue"
            >
              <div class="col-12">
                <div class="sat1">
                  <img
                    src="@/assets/images/bigico_npsTable_positive.png"
                    srcset="@/assets/images/bigico_npsTable_positive@2x.png 2x, @/assets/images/bigico_npsTable_positive@3x.png 3x"
                    width="16"
                  >
                  <transition
                    name="fadefast"
                    mode="out-in"
                  >
                    <span
                      v-if="valuePercent"
                      key="valuePercent"
                    >{{ brother.totalPromotersPctg | addDecimal | addPercent }}</span>
                    <span
                      v-else
                      key="valueTotal"
                    >{{ brother.totalPromoters }}</span>
                  </transition>
                </div>
                <div class="sat2">
                  <img
                    src="@/assets/images/bigico_npsTable_neutral.png"
                    srcset="@/assets/images/bigico_npsTable_neutral@2x.png 2x, @/assets/images/bigico_npsTable_neutral@3x.png 3x"
                    width="16"
                  >
                  <transition
                    name="fadefast"
                    mode="out-in"
                  >
                    <span
                      v-if="valuePercent"
                      key="valuePercent"
                    >{{ brother.totalPassivesPctg | addDecimal | addPercent }}</span>
                    <span
                      v-else
                      key="valueTotal"
                    >{{ brother.totalPassives }}</span>
                  </transition>
                </div>
                <div class="sat3">
                  <img
                    src="@/assets/images/bigico_npsTable_negative.png"
                    srcset="@/assets/images/bigico_npsTable_negative@2x.png 2x, @/assets/images/bigico_npsTable_negative@3x.png 3x"
                    width="16"
                  >
                  <transition
                    name="fadefast"
                    mode="out-in"
                  >
                    <span
                      v-if="valuePercent"
                      key="valuePercent"
                    >{{ brother.totalDetractorsPctg | addDecimal | addPercent }}</span>
                    <span
                      v-else
                      key="valueTotal"
                    >{{ brother.totalDetractors }}</span>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import RadialProgressBar from './RadialProgress'
import {platformMixin} from '@/platformMixin'

export default {
  components: {
    RadialProgressBar
  },
  filters: {
    translateName(value) {
      let nombre = value
      switch (nombre) {
        case 'country':
          nombre = 'País'
          break
        case 'zone':
          nombre = 'Zona'
          break
        case 'region':
          nombre = 'Región'
          break
        case 'area':
          nombre = 'Área'
          break
        case 'center':
          nombre = 'Centro'
          break
        default:
          nombre = 'Continente'
          break
      }
      return nombre
    },
    addDecimal(value) {
      const valor = parseFloat(value)
      return valor.toFixed(2)
    },
    addPercent(value) {
      return `${value}%`
    }
  },
  mixins: [platformMixin],
  props: ['brothers', 'hierarchy', 'dynamicCampaignName'],
  data() {
    return {
      valuePercent: true
    }
  },
  computed: {
    campaignName() {
      let name
      if (this.dynamicCampaignName === '') {
        name = this.$store.state.campaignData.Name
      } else {
        name = this.dynamicCampaignName
      }
      return name
    },
  },
  methods: {
    hideModal() {
      this.$emit('dashboard-listview-opened', false)
    },
    toggleValue() {
      this.valuePercent = !this.valuePercent
    }
  }
}
</script>
