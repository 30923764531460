import { getNpsenseApiUrl } from './variables';

export const platformMixin = {
  computed: {
    platform() {
      return (window.cordova) ? window.cordova.platformId : '';
    },
    npsenseApiDataHeaders() {
      return {
        'Content-type': 'text/xml; charset=utf-8'
      };
    },
    npsenseApiUrl() {
      return getNpsenseApiUrl();
    }
  }
};
