<template>
  <div>
    <div class="topbar" :class="platform">
      <div class="container">
        <div class="grid grid-bleed">
          <div class="col-8">
            <template v-if="hierarchyPreviousName">
              <router-link class="btn btn-link" :to="'/Dashboard/' + hierarchyPrevious + '/' + hierarchyPreviousId">
                <img src="@/assets/images/arrow-left.png" width="13"
                  srcset="@/assets/images/arrow-left@2x.png 2x, @/assets/images/arrow-left@3x.png 3x">
                {{ hierarchyPreviousName | capitalize }}
              </router-link>
            </template>
          </div>
          <div class="col-4 text-right">
            <button class="btn btn-link" :disabled="motivesDisabled()" @click="showComments()">
              <img src="@/assets/images/ico_table_comments.png"
                srcset="@/assets/images/ico_table_comments@2x.png 2x, @/assets/images/ico_table_comments@3x.png 3x">
            </button>&nbsp;&nbsp;
            <button class="btn btn-link right" :disabled="motivesDisabled()" @click="showMotives()">
              <img src="@/assets/images/ico_table_motives.png"
                srcset="@/assets/images/ico_table_motives@2x.png 2x, @/assets/images/ico_table_motives@3x.png 3x">
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="fondoAzul view-nps" :class="platform">
      <transition name="fade">
        <swiper id="tabs" key="loaded" ref="npsSwiper" :options="swiperOptions" class="npstabs2">
          <swiper-slide v-for="brother in brothers" :key="brother.id" class="tab col-grid">
            <div class="data text-center">
              <div class="container">
                <div class="grid">
                  <div class="col-12 col-bleed">
                    <div class="zone">
                      {{ brother.name }}
                    </div>
                    <a v-if="!$store.state.campaignData.ID" href="#" class="campaign-link"
                      @click.prevent="showCampaigns(brother.name, brother.id)">
                      {{ campaignName }}
                    </a>
                    <p v-else style="color:#EF3D59">
                      {{ campaignName }}
                    </p>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-3" style="padding:0">
                    <div class="nps1">
                      <div class="nps1a">
                        {{ brother.totalSurveys }}
                      </div>
                      <div class="nps1b">
                        {{ brother.totalAnswers }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6 align-self-center">
                    <div class="nps2">
                      {{ brother.totalNps | addDecimal }}<span class="percentage">%</span>
                    </div>
                  </div>
                  <div class="col-3" style="padding:0">
                    <div class="nps3">
                      <div class="circleProgress">
                        <radial-progress-bar :completed-steps="parseInt(brother.totalAnswerRate)">
                          <span>{{ parseInt(brother.totalAnswerRate) | addPercent }}</span>
                        </radial-progress-bar>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid grid-bleed sat" @click="toggleValue">
                  <div class="col-12">
                    <div class="sat1">
                      <img src="@/assets/images/bigico_npsTable_positive.png"
                        srcset="@/assets/images/bigico_npsTable_positive@2x.png 2x, @/assets/images/bigico_npsTable_positive@3x.png 3x"
                        width="16">
                      <transition name="fadefast" mode="out-in">
                        <span v-if="valuePercent" key="valuePercent">{{ brother.totalPromotersPctg | addDecimal |
                          addPercent }}</span>
                        <span v-else key="valueTotal">{{ brother.totalPromoters }}</span>
                      </transition>
                    </div>
                    <div class="sat2">
                      <img src="@/assets/images/bigico_npsTable_neutral.png"
                        srcset="@/assets/images/bigico_npsTable_neutral@2x.png 2x, @/assets/images/bigico_npsTable_neutral@3x.png 3x"
                        width="16">
                      <transition name="fadefast" mode="out-in">
                        <span v-if="valuePercent" key="valuePercent">{{ brother.totalPassivesPctg | addDecimal |
                          addPercent }}</span>
                        <span v-else key="valueTotal">{{ brother.totalPassives }}</span>
                      </transition>
                    </div>
                    <div class="sat3">
                      <img src="@/assets/images/bigico_npsTable_negative.png"
                        srcset="@/assets/images/bigico_npsTable_negative@2x.png 2x, @/assets/images/bigico_npsTable_negative@3x.png 3x"
                        width="16">
                      <transition name="fadefast" mode="out-in">
                        <span v-if="valuePercent" key="valuePercent">{{ brother.totalDetractorsPctg | addDecimal |
                          addPercent }}</span>
                        <span v-else key="valueTotal">{{ brother.totalDetractors }}</span>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="data curyear text-center">
              <div class="container">
                <div class="grid">
                  <div class="col-12 col-bleed-y">
                    <div class="zone">
                      {{ (new Date()).getFullYear() }}
                    </div>
                  </div>
                </div>
                <div class="grid">
                  <div class="col-3" style="padding:0">
                    <div class="nps1">
                      <div class="nps1a">
                        {{ brother.curyearSurveys }}
                      </div>
                      <div class="nps1b">
                        {{ brother.curyearAnswers }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-bleed-x align-self-center">
                    <div class="nps2">
                      {{ brother.curyearNps | addDecimal }}<span class="percentage">%</span>
                    </div>
                  </div>
                  <div class="col-3" style="padding:0">
                    <div class="nps3">
                      <div class="circleProgress">
                        <radial-progress-bar :completed-steps="parseInt(brother.curyearAnswerRate)">
                          <span>{{ parseInt(brother.curyearAnswerRate) | addPercent }}</span>
                        </radial-progress-bar>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="grid grid-bleed sat" @click="toggleValue">
                  <div class="col-12">
                    <div class="sat1">
                      <img src="@/assets/images/bigico_npsTable_positive.png"
                        srcset="@/assets/images/bigico_npsTable_positive@2x.png 2x, @/assets/images/bigico_npsTable_positive@3x.png 3x"
                        width="16">
                      <transition name="fadefast" mode="out-in">
                        <span v-if="valuePercent" key="valuePercent">{{ brother.curyearPromotersPctg | addDecimal |
                          addPercent }}</span>
                        <span v-else key="valueTotal">{{ brother.curyearPromoters }}</span>
                      </transition>
                    </div>
                    <div class="sat2">
                      <img src="@/assets/images/bigico_npsTable_neutral.png"
                        srcset="@/assets/images/bigico_npsTable_neutral@2x.png 2x, @/assets/images/bigico_npsTable_neutral@3x.png 3x"
                        width="16">
                      <transition name="fadefast" mode="out-in">
                        <span v-if="valuePercent" key="valuePercent">{{ brother.curyearPassivesPctg | addDecimal |
                          addPercent }}</span>
                        <span v-else key="valueTotal">{{ brother.curyearPassives }}</span>
                      </transition>
                    </div>
                    <div class="sat3">
                      <img src="@/assets/images/bigico_npsTable_negative.png"
                        srcset="@/assets/images/bigico_npsTable_negative@2x.png 2x, @/assets/images/bigico_npsTable_negative@3x.png 3x"
                        width="16">
                      <transition name="fadefast" mode="out-in">
                        <span v-if="valuePercent" key="valuePercent">{{ brother.curyearDetractorsPctg | addDecimal |
                          addPercent }}</span>
                        <span v-else key="valueTotal">{{ brother.curyearDetractors }}</span>
                      </transition>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="childs">
              <div v-for="(child, index) in brother.childs" :key="index" class="child-link">
                <router-link
                  :to="{ name: 'npsView', params: { hierarchy: hierarchyNext, id: child.id, previousId: brother.id, previousName: brother.name, activeIndex: index } }">
                  <span class="pull-right">
                    <img src="@/assets/images/arrow-right.png" style="width:13px !important; height: auto;"
                      srcset="@/assets/images/arrow-right@2x.png 2x, @/assets/images/arrow-right@3x.png 3x">
                  </span>
                  {{ child.name }}
                </router-link>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </transition>
    </div>

    <div class="bottombar">
      <div class="container">
        <div class="grid grid-bleed">
          <div class="col-2">
            <button class="btn btn-icon" @click="showConfig">
              <img src="@/assets/images/ico_settings.png"
                srcset="@/assets/images/ico_settings@2x.png 2x, @/assets/images/ico_settings@3x.png 3x">
            </button>
          </div>
          <div class="col-8 text-center">
            <div v-for="(brother, index) in brothers" :key="brother.id" :class="activeIndex === index ? 'active' : ''"
              class="tab-pager" />
          </div>
          <div class="col-2 text-right">
            <button :class="{ 'pull-left': multipleBrothers }" class="btn btn-icon" @click="showCalendarview">
              <img src="@/assets/images/ico_cal.png"
                srcset="@/assets/images/ico_cal@2x.png 2x, @/assets/images/ico_cal@3x.png 3x">
            </button>
            <button v-if="multipleBrothers" class="btn btn-icon pull-right" @click="showListview">
              <img src="@/assets/images/ico_menu.png"
                srcset="@/assets/images/ico_menu@2x.png 2x, @/assets/images/ico_menu@3x.png 3x">
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Config component -->
    <transition name="zoom">
      <template v-if="dashboardConfig">
        <dashboard-config :dashboard-config="dashboardConfig" @dashboard-opened="dashboardConfig = $event" />
      </template>
    </transition>

    <!-- Calendarview component -->
    <transition name="zoom">
      <template v-if="dashboardCalendarview">
        <dashboard-calendarview :dashboard-calendarview="dashboardCalendarview" :current-id="this.$store.state.currentID"
          :current-name="this.$store.state.currentName" @dashboard-calendarview-opened="dashboardCalendarview = $event" />
      </template>
    </transition>

    <!-- Listview component -->
    <transition name="slide-down">
      <template v-if="dashboardListview">
        <dashboard-listview :dashboard-listview="dashboardListview" :brothers="brothers" :hierarchy="hierarchyCurrent"
          :dynamic-campaign-name="dynamicCampaignName" @dashboard-listview-opened="dashboardListview = $event" />
      </template>
    </transition>

    <!-- Campaigns component -->
    <transition name="zoom">
      <template v-if="dashboardCampaigns">
        <dashboard-campaigns :dashboard-campaigns="dashboardCampaigns" @dynamic-campaign-id="dynamicCampaignId = $event"
          @dynamic-campaign-name="dynamicCampaignName = $event" @dashboard-motives-opened="dashboardMotives = $event"
          @dashboard-comments-opened="dashboardComments = $event"
          @dashboard-campaigns-opened="dashboardCampaigns = $event" />
      </template>
    </transition>

    <!-- Motives component -->
    <transition name="zoom-right">
      <template v-if="dashboardMotives">
        <dashboard-motives :dashboard-motives="dashboardMotives" :dynamic-campaign-id="dynamicCampaignId"
          :dynamic-campaign-name="dynamicCampaignName" @dashboard-motives-opened="dashboardMotives = $event" />
      </template>
    </transition>

    <!-- Comments component -->
    <transition name="zoom-left">
      <template v-if="dashboardComments">
        <dashboard-comments :dashboard-comments="dashboardComments" :dynamic-campaign-id="dynamicCampaignId"
          :dynamic-campaign-name="dynamicCampaignName" @dashboard-comments-opened="dashboardComments = $event" />
      </template>
    </transition>
  </div>
</template>

<script>
// import 'swiper/dist/css/swiper.min.css'
import 'swiper/swiper-bundle.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import RadialProgressBar from './RadialProgress'
import DashboardConfig from './DashboardConfig'
import DashboardCalendarview from './DashboardCalendarview'
import DashboardListview from './DashboardListview'
import DashboardCampaigns from './DashboardCampaigns'
import DashboardMotives from './DashboardMotives'
import DashboardComments from './DashboardComments'
import { platformMixin } from '@/platformMixin'

export default {
  components: {
    Swiper,
    SwiperSlide,
    DashboardConfig,
    DashboardCalendarview,
    DashboardListview,
    DashboardCampaigns,
    DashboardComments,
    DashboardMotives,
    RadialProgressBar
  },
  filters: {
    capitalize(value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    addDecimal(value) {
      const valor = parseFloat(value)
      return valor.toFixed(2).trim()
    },
    addPercent(value) {
      return `${value}%`
    }
  },
  mixins: [platformMixin],
  async beforeRouteUpdate(to, from, next) {
    if (!this.dashboardCalendarview && !this.dashboardCampaigns) {
      this.clearTabs()
      this.setFamilyNav(to.params.previousId, to.params.previousName)
      this.initNPS(to.params.id, to.params.hierarchy)
      // Set current hierarchy
      await this.$store.dispatch('setCurrentHierarchy', to.params.hierarchy)
      await this.$store.dispatch('setCurrentID', to.params.id)
      next()
    }
  },
  data() {
    return {
      valuePercent: true,
      dashboardConfig: false,
      dashboardCalendarview: false,
      dashboardListview: false,
      dashboardCampaigns: false,
      dashboardMotives: false,
      dashboardComments: false,
      dynamicCampaignId: '',
      dynamicCampaignName: '',
      hierarchy: this.$store.state.geoSelected.value,
      hierarchyCurrent: '',
      hierarchyPrevious: '',
      hierarchyPreviousName: '',
      hierarchyPreviousId: '',
      hierarchyNext: '',
      hierarchyNextId: '',
      campaignName: this.$store.state.campaignData.Name,
      campaignID: this.$store.state.campaignData.ID,
      brothersCount: '',
      brothersCountActive: 0,
      brothersTemp: [],
      brothers: null,
      currentID: null,
      currentName: null,
      familyNav: '',
      activeIndex: 0,
      npsTabsTo: 0,
      swiperOptions: {
        effect: 'slide'
      }
    }
  },
  computed: {
    multipleBrothers() {
      return (this.brothers) ? this.brothers.length > 1 : false
    },
    swiper() {
      return this.$refs.npsSwiper.$swiper
    }
  },
  async created() {
    await this.$store.dispatch('setCurrentHierarchy', this.$route.params.hierarchy)
    this.setFamilyNav()
    this.initNPS(this.$route.params.id, this.$route.params.hierarchy)
  },
  mounted() {
    this.swiper.on('slideChange', async () => {
      this.activeIndex = this.swiper.activeIndex
      await this.$store.dispatch('setCurrentID', this.brothers[this.swiper.activeIndex].id)
      await this.$store.dispatch('setCurrentName', this.brothers[this.swiper.activeIndex].name)
      await this.$store.dispatch('setCurrentHierarchy', this.$route.params.hierarchy)
      this.currentID = this.brothers[this.swiper.activeIndex].id
      this.currentName = this.brothers[this.swiper.activeIndex].name
    })
  },
  methods: {
    async setFamilyNav(previousID, previousName) {
      // Navigation
      let familyNavTemp = []
      if (this.$store.state.familyNav !== '[]') {
        familyNavTemp = this.$store.state.familyNav
      }
      if (previousID) {
        familyNavTemp.push({
          id: previousID,
          name: previousName,
        })
      } else {
        if (familyNavTemp.length > 1) {
          familyNavTemp.pop()
        } else {
          familyNavTemp = []
        }
      }
      await this.$store.dispatch('setFamilyNav', familyNavTemp)
      this.familyNav = familyNavTemp
      if (this.familyNav.length > 0) {
        const currentFamilyNav = this.familyNav
        const currentFamilyNavLength = currentFamilyNav.length - 1
        this.hierarchyPreviousId = currentFamilyNav[currentFamilyNavLength].id
        this.hierarchyPreviousName = currentFamilyNav[currentFamilyNavLength].name
      } else {
        this.hierarchyPreviousId = ''
        this.hierarchyPreviousName = ''
      }
    },
    initNPS(hierarchyID, hierarchyName) {
      this.$Progress.start()
      // HierarchyID to numeric to avoid comparision error
      const numericHierarchyID = parseInt(hierarchyID, 10);
      // SET current, previous y next hierarchy
      this.hierarchyCurrent = hierarchyName
      this.hierarchyPrevious = this.getPreviousHierarchy(this.hierarchyCurrent)
      this.hierarchyNext = this.getNextHierarchy(this.hierarchyCurrent)
      // Estalecemos hermanos de la jerarquia seleccionada
      let stateLevel
      switch (this.hierarchyCurrent) {
        case 'continent':
          stateLevel = this.$store.state.continent
          break
        case 'country':
          stateLevel = this.$store.state.country
          break
        case 'zone':
          stateLevel = this.$store.state.zone
          break
        case 'region':
          stateLevel = this.$store.state.region
          break
        case 'area':
          stateLevel = this.$store.state.area
          break
        case 'center':
          stateLevel = this.$store.state.center
          break
      }

      this.brothersCount = stateLevel
      let newBrothersParent
      Object.keys(this.brothersCount).forEach((val) => {
        if (this.brothersCount[val].id === numericHierarchyID) {
          newBrothersParent = this.brothersCount[val].previousId
        }
      })

      const tmpBrothers = []
      Object.keys(this.brothersCount).forEach((val) => {
        if (this.brothersCount[val].previousId === newBrothersParent) {
          tmpBrothers.push({
            id: this.brothersCount[val].id,
            name: this.brothersCount[val].name,
          })
        }
      })
      this.brothersCount = tmpBrothers
      // Indicamos que ya tenemos 1 hermano seguro
      this.brothersCountActive++
      // Cargamos la info del primer hermano
      this.getNPSData(this.brothersCount[0].id, this.brothersCount[0].name)
    },
    async getNPSData(id) {
      // Si tiene hijos pasamos datos normales, si no los tiene es CENTRO y se envían otros datos
      let geoID;
      let centerID;
      let campaignID;
      let waveID = '';
      if (this.$store.state.waveData.ID) {
        geoID = '';
        centerID = '';
        campaignID = '';
        waveID = this.$store.state.waveData.ID;
      } else {
        campaignID = this.$store.state.campaignData.ID;
        if (this.hierarchyNext) {
          geoID = id;
          centerID = this.$store.state.centerData.ID;
        } else {
          geoID = '';
          centerID = id;
        }
      }

      // Obtenemos los tipos de nivel que hay
      const dataRequest = '<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body>' +
        '<apiGetNPSData>' +
        '<sessionkey>' + this.$store.state.login.token + '</sessionkey>' +
        '<geo_id>' + geoID + '</geo_id>' +
        '<center_id>' + centerID + '</center_id>' +
        '<campaign_id>' + campaignID + '</campaign_id>' +
        '<wave_id>' + waveID + '</wave_id>' +
        '<answer_type>json</answer_type>' +
        '</apiGetNPSData></soap:Body></soap:Envelope>';

      try {
        const response = await fetch(this.npsenseApiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/xml',
            ...this.npsenseApiDataHeaders,
          },
          body: dataRequest
        });

        if (!response.ok) {
          throw new Error('Respuesta no exitosa desde el servidor');
        }

        const data = await response.text();
        const parser = new DOMParser();
        const result = parser.parseFromString(data, 'text/xml');
        const npsResponse = JSON.parse(result.getElementsByTagName('ns1:apiGetNPSDataResponse')[0].textContent);

        this.brothersTemp.push({
          id: this.brothersCount[this.brothersCountActive - 1].id,
          name: this.brothersCount[this.brothersCountActive - 1].name,
          totalNps: npsResponse.total_nps,
          totalPromotersPctg: npsResponse.total_promoters_pctg,
          totalPromoters: npsResponse.total_promoters,
          totalPassivesPctg: npsResponse.total_passives_pctg,
          totalPassives: npsResponse.total_passives,
          totalDetractorsPctg: npsResponse.total_detractors_pctg,
          totalDetractors: npsResponse.total_detractors,
          totalSurveys: npsResponse.total_surveys,
          totalAnswers: npsResponse.total_answers,
          totalAnswerRate: npsResponse.total_answerrate,
          curyearNps: npsResponse.curyear_nps,
          curyearPromotersPctg: npsResponse.curyear_promoters_pctg,
          curyearPromoters: npsResponse.curyear_promoters,
          curyearPassivesPctg: npsResponse.curyear_passives_pctg,
          curyearPassives: npsResponse.curyear_passives,
          curyearDetractorsPctg: npsResponse.curyear_detractors_pctg,
          curyearDetractors: npsResponse.curyear_detractors,
          curyearSurveys: npsResponse.curyear_surveys,
          curyearAnswers: npsResponse.curyear_answers,
          curyearAnswerRate: npsResponse.curyear_answerrate
        });

        // Si tiene hijos los añadimos al objeto
        let tmpChildsOrg;
        if (this.hierarchyNext) {
          this.brothersTemp[this.brothersCountActive - 1].childs = [];
          switch (this.hierarchyNext) {
            case 'country':
              tmpChildsOrg = this.$store.state.country;
              break;
            case 'zone':
              tmpChildsOrg = this.$store.state.zone;
              break;
            case 'region':
              tmpChildsOrg = this.$store.state.region;
              break;
            case 'area':
              tmpChildsOrg = this.$store.state.area;
              break;
            case 'center':
              tmpChildsOrg = this.$store.state.center;
              break;
          }
          Object.keys(tmpChildsOrg).forEach((val) => {
            if (tmpChildsOrg[val].previousId === this.brothersTemp[this.brothersCountActive - 1].id) {
              this.brothersTemp[this.brothersCountActive - 1].childs.push({
                id: tmpChildsOrg[val].id,
                name: tmpChildsOrg[val].name,
              });
            }
          });
        }

        // Callback para cargar el siguiente hermano
        if (this.brothersCount.length > 1) {
          if (this.brothersCountActive < this.brothersCount.length) {
            this.getNPSData(this.brothersCount[this.brothersCountActive].id);
            this.brothersCountActive++;
          } else {
            this.brothers = this.brothersTemp;
            this.brothersTemp = [];
            this.brothersCount = '';
            this.brothersCountActive = 0;
            setTimeout(() => {
              this.initTabs();
            }, 15);
          }
        } else {
          this.brothers = this.brothersTemp;
          this.brothersTemp = [];
          this.brothersCount = '';
          this.brothersCountActive = 0;
          this.$store.dispatch('setCurrentID', this.brothers[0].id);
          this.$store.dispatch('setCurrentName', this.brothers[0].name);
        }
        this.$Progress.finish();

      } catch (error) {
        this.brothers = this.brothersTemp;
        this.brothersTemp = [];
        this.brothersCount = '';
        this.brothersCountActive = 0;
        const parser = new DOMParser();
        const result = parser.parseFromString(error.message, 'text/xml');
        console.error('Error al obtener datos NPS:', result);
        // Aquí puedes manejar más detalles del error si es necesario
      }
    },
    initTabs() {
      this.brothers.forEach((v, i) => {
        if (this.brothers[i].id === this.$route.params.id) {
          this.npsTabsTo = i
          this.activeIndex = i
        }
      })
      this.swiper.slideTo(this.npsTabsTo, 0)
    },
    clearTabs() {
      this.brothers = null
      this.brothersTemp = []
      this.brothersCount = ''
      this.brothersCountActive = 0
    },
    clearSwiper() {
      this.swiper.destroy()
    },
    motivesDisabled() {
      return this.campaignID === ''
    },
    getPreviousHierarchy(currentHierarchy) {
      let prevHierarchy
      if (currentHierarchy === 'center') {
        prevHierarchy = 'area'
      } else if (currentHierarchy === 'area') {
        prevHierarchy = 'region'
      } else if (currentHierarchy === 'region') {
        prevHierarchy = 'zone'
      } else if (currentHierarchy === 'zone') {
        prevHierarchy = 'country'
      } else if (currentHierarchy === 'country') {
        prevHierarchy = 'continent'
      } else if (currentHierarchy === 'continent') {
        prevHierarchy = null
      }
      return prevHierarchy
    },
    getNextHierarchy(currentHierarchy) {
      let nextHierarchy
      if (currentHierarchy === 'continent') {
        nextHierarchy = 'country'
      } else if (currentHierarchy === 'country') {
        nextHierarchy = 'zone'
      } else if (currentHierarchy === 'zone') {
        nextHierarchy = 'region'
      } else if (currentHierarchy === 'region') {
        nextHierarchy = 'area'
      } else if (currentHierarchy === 'area') {
        nextHierarchy = 'center'
      }
      return nextHierarchy
    },
    showConfig() {
      this.dashboardConfig = true
    },
    showCalendarview() {
      this.dashboardCalendarview = true
    },
    showListview() {
      this.dashboardListview = true
    },
    showCampaigns() {
      this.dashboardCampaigns = true
    },
    showMotives() {
      if (this.dashboardCampaigns === false) {
        this.dynamicCampaignId = ''
        this.dynamicCampaignName = ''
      }
      this.dashboardMotives = true
    },
    showComments() {
      if (this.dashboardCampaigns === false) {
        this.dynamicCampaignId = ''
        this.dynamicCampaignName = ''
      }
      this.dashboardComments = true
    },
    toggleValue() {
      this.valuePercent = !this.valuePercent
    }
  }
}
</script>
