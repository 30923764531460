import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'
import VueTouch from 'vue-touch'

import App from './App'
import router from './router'
import { store } from './store/store'

Vue.config.productionTip = false

const VueProgressBaroptions = {
  color: '#0FEA70',
  failedColor: '#EF3D59',
  thickness: '3px',
  transition: {
    speed: '0.3s',
    opacity: '0.3s',
    termination: 300
  }
}

Vue.use(VueProgressBar, VueProgressBaroptions)
Vue.use(VueTouch, {name: 'v-touch'})

VueTouch.config.swipe = {
  direction: 'horizontal'
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
