<template>
  <div class="dashboard-comp zindex500">
    <div
      class="topbar"
      :class="platform"
    >
      <div class="close">
        <a
          href="#"
          class="btn btn-link btn-close"
          @click.prevent="hideModal"
        >&times;</a>
      </div>
      <div class="titleCampaign">
        {{ this.$store.state.currentName }}
        <p class="subtitle">
          {{ campaignName }}
        </p>
      </div>
    </div>

    <div class="fondoAzul dashboard-popup-commentinfo">
      <div class="commentinfo">
        <div class="container">
          <div class="grid">
            <div class="col-12 col-bleed-x">
              <div class="info">
                {{ userName }}
                <span class="pull-right">
                  <div
                    v-if="commentNps > 5"
                    class="badge green"
                  >{{ commentNps }}</div>
                  <div
                    v-if="commentNps === 5"
                    class="badge yellow"
                  >{{ commentNps }}</div>
                  <div
                    v-if="commentNps < 5"
                    class="badge red"
                  >{{ commentNps }}</div>
                </span>
                <br>
                <span class="fadeout">
                  {{ commentDate }}<br>
                  {{ commentWave }}<br>
                </span>
                CI: {{ internalCode }}<br>
                <a :href="'tel:' + phoneNumber">{{ phoneNumber }}</a><br>
                <a :href="'mailto:' + emailAddress">{{ emailAddress }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="fondoAzul dashboard-popup-fields"
      :class="platform"
    >
      <div class="fields">
        <div class="field-name">
          Campo para datos internos
        </div>
        <div class="field-data">
          <pre>{{ internalDataField | emptyVal }}</pre>
        </div>
        <div class="field-name">
          Campo 1
        </div>
        <div class="field-data">
          {{ field1 | emptyVal }}
        </div>
        <div class="field-name">
          Campo 2
        </div>
        <div class="field-data">
          {{ field2 | emptyVal }}
        </div>
        <div class="field-name">
          Campo 3
        </div>
        <div class="field-data">
          {{ field3 | emptyVal }}
        </div>
        <div class="field-name">
          Campo 4
        </div>
        <div class="field-data">
          {{ field4 | emptyVal }}
        </div>
        <div class="field-name">
          Campo 5
        </div>
        <div class="field-data">
          {{ field5 | emptyVal }}
        </div>
        <div class="field-name">
          Campo 6
        </div>
        <div class="field-data">
          {{ field6 | emptyVal }}
        </div>
        <div class="field-name">
          Campo 7
        </div>
        <div class="field-data">
          {{ field7 | emptyVal }}
        </div>
        <div class="field-name">
          Campo 8
        </div>
        <div class="field-data">
          {{ field8 | emptyVal }}
        </div>
        <div class="field-name">
          Campo 9
        </div>
        <div class="field-data">
          {{ field9 | emptyVal }}
        </div>
        <div class="field-name">
          Campo 10
        </div>
        <div class="field-data">
          {{ field10 | emptyVal }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {platformMixin} from '@/platformMixin'

export default {
  filters: {
    emptyVal(value) {
      return (value) ? value : '-'
    }
  },
  mixins: [platformMixin],
  props: {
    userName: {
      type: String,
      required: true
    },
    commentDate: {
      type: String,
      required: true
    },
    commentWave: {
      type: String,
      required: true
    },
    campaignName: {
      type: String,
      required: true
    },
    centerName: {
      type: String,
      required: true
    },
    internalCode: {
      type: String,
      required: true
    },
    phoneNumber: {
      type: String,
      required: true
    },
    emailAddress: {
      type: String,
      required: true
    },
    internalDataField: {
      type: String,
      required: true
    },
    commentNps: {
      type: String,
      required: true
    },
    field1: {
      type: String,
      required: true
    },
    field2: {
      type: String,
      required: true
    },
    field3: {
      type: String,
      required: true
    },
    field4: {
      type: String,
      required: true
    },
    field5: {
      type: String,
      required: true
    },
    field6: {
      type: String,
      required: true
    },
    field7: {
      type: String,
      required: true
    },
    field8: {
      type: String,
      required: true
    },
    field9: {
      type: String,
      required: true
    },
    field10: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errorApi: false,
    }
  },
  methods: {
    hideModal() {
      this.$emit('dashboard-commentinfo-opened', false)
    },
  }
}
</script>
