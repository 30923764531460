<template>
  <div class="config">
    <div
      class="topbar"
      :class="platform"
    >
      <div class="container">
        <div class="grid grid-bleed">
          <div class="col-6">
            <router-link
              :to="{name: 'ConfigIntro'}"
              class="btn btn-link"
            >
              Anterior
            </router-link>
          </div>
          <div class="col-6 text-right">
            <button
              class="btn btn-link"
              :disabled="nextDisabled"
              @click="setGeoHierarchy()"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container"
      style="height: auto !important"
    >
      <div
        class="grid fondoAzul config-process grid justify-center align-center"
        :class="platform"
      >
        <div class="col-10">
          <div class="content">
            <img
              src="@/assets/images/big_ico_level.png"
              width="100"
              srcset="@/assets/images/big_ico_level@2x.png 2x, @/assets/images/big_ico_level@3x.png 3x"
            >
            <h1>¿Qué nivel de acceso quieres?</h1>
          </div>
        </div>
      </div>
    </div>

    <div
      class="fondoNegro config-process"
      :class="platform"
    >
      <ul
        v-if="geos"
        key="geosIfr"
        class="list"
      >
        <li
          v-for="(value, key) in geos"
          :key="key"
        >
          <label
            v-if="key !== 'geo'"
            class="radio-label"
          >
            <span>{{ value }}</span>
            <input
              v-model="selectedGeo"
              :value="{value: key, name: value}"
              type="radio"
              name="nivel"
              class="list-checkbox"
            >
            <span class="radio-button" />
          </label>
        </li>
      </ul>
      <div
        v-else
        class="text-center"
      >
        <div class="spacer rem2" />
        <img
          src="@/assets/images/loading.gif"
          width="21"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { platformMixin } from '@/platformMixin'

  export default {
    mixins: [platformMixin],
    data() {
      return {
        selectedGeo: null,
      }
    },
    computed: {
      ...mapGetters({
        geos: 'getGeoResponse'
      }),
      nextDisabled() {
        return !this.selectedGeo
      }
    },
    mounted() {
      this.$Progress.start()
      this.getGeoHierarchy()
    },
    methods: {
      getGeoHierarchy() {
        const dataRequest = '<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body>' +
            '<apiGetGeoHierarchy><sessionkey>' + this.$store.state.login.token + '</sessionkey>' +
            '<answer_type>json</answer_type></apiGetGeoHierarchy></soap:Body></soap:Envelope>';
        
        fetch(this.npsenseApiUrl, {
            method: 'POST',
            headers: {
                ...this.npsenseApiDataHeaders,
                'Content-Type': 'application/xml'
            },
            body: dataRequest
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Respuesta no exitosa desde el servidor');
            }
            return response.text();
        })
        .then(data => {
            const parser = new DOMParser();
            const result = parser.parseFromString(data, 'text/xml');
            const geoResponse = JSON.parse(result.getElementsByTagName('ns1:apiGetGeoHierarchyResponse')[0].textContent);
            this.$store.dispatch('saveGeoResponse', geoResponse);
            this.$Progress.finish();
        })
        .catch(error => {
            this.$Progress.fail();
            // Dado que es probable que el error no sea XML, revisamos primero si se puede analizar.
            try {
                const parser = new DOMParser();
                const result = parser.parseFromString(error.message, 'text/xml');
                const errorResponse = result.getElementsByTagName('faultstring')[0].textContent;
                alert('Ha ocurrido un error: ' + errorResponse);
            } catch (parsingError) {
                alert('Ha ocurrido un error desconocido.');
            }
            this.hideModal();
        });
      },
      async setGeoHierarchy() {
        // Access level save
        let selectedGeo = this.selectedGeo.value.split('_')
        selectedGeo = {
          value: selectedGeo[0],
          name: this.selectedGeo.name
        }
        await this.$store.dispatch('setGeoSelected', selectedGeo)
        this.$router.push({name: 'ConfigGeoZone'})
      }
    }
  }
</script>
