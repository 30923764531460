<template>
  <div class="config">
    <div
      class="topbar"
      :class="platform"
    >
      <div class="container">
        <div class="grid grid-bleed">
          <div class="col-6">
            <router-link
              class="btn btn-link"
              :to="{name: 'ConfigGeoZone'}"
            >
              Anterior
            </router-link>
          </div>
          <div class="col-6 text-right">
            <button
              class="btn btn-link"
              :disabled="nextDisabled()"
              @click="setCampaign()"
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div
        class="grid fondoAzul config-process justify-center align-center"
        :class="platform"
      >
        <div class="col-10">
          <div class="content">
            <img
              src="@/assets/images/big_ico_campaign.png"
              srcset="@/assets/images/big_ico_campaign@2x.png 2x, @/assets/images/big_ico_campaign@3x.png 3x"
              width="100"
            >
            <h1>¿Qué campaña te gustaría monitorizar?</h1>
          </div>
        </div>
      </div>
    </div>

    <div
      class="fondoNegro config-process"
      :class="platform"
    >
      <div class="previousname">
        {{ previousName }}
      </div>
      <ul
        v-if="campaigns"
        class="list"
      >
        <li
          v-for="campaign in campaigns"
          :key="campaign.id"
        >
          <label class="radio-label">
            <div class="label-overflow">{{ campaign.name }}</div>
            <input
              v-model="selectedCampaign"
              type="radio"
              :value="campaign.id"
              class="list-checkbox"
              @change="setCampaignName(campaign.name)"
            >
            <span class="radio-button" />
          </label>
        </li>
      </ul>
      <div
        v-else-if="errorApi"
        key="error"
        class="text-center"
      >
        <div class="spacer rem2" />
        <div class="spacer rem2" />
        <p class="alert info">
          No hay campañas asociadas pero puede continuar con la configuración.
        </p>
      </div>
      <div
        v-else
        class="text-center"
      >
        <div class="spacer rem2" />
        <img
          src="@/assets/images/loading.gif"
          width="21"
          alt=""
        >
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { platformMixin } from '@/platformMixin'

  export default {
    mixins: [platformMixin],
    data() {
      return {
        errorApi: false,
        selectedCampaign: '',
        selectedCampaignName: '',
        campaigns: '',
        campaignsAll: []
      }
    },
    computed: {
      ...mapState(['geoSelected', 'geoData', 'centerData']),
      previousName() {
        // Comprobamos si necesitamos geoID o centerID
        return (this.$store.state.centerData.Name) ? this.$store.state.centerData.Name : this.$store.state.currentName
      }
    },
    created() {
      this.$Progress.start()
      this.getCampaigns()
    },
    methods: {
      nextDisabled() {
        return this.selectedCampaign === ''
      },
      getCampaigns() {
        // Obtenemos las campañas de la zona seleccionada
        const dataRequest = '<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body>' +
          '<apiGetCampaigns><sessionkey>' + this.$store.state.login.token + '</sessionkey>' +
          '<geo_id>' + this.geoData.ID + '</geo_id>' +
          '<center_id>' + this.centerData.ID + '</center_id>' +
          '<answer_type>json</answer_type></apiGetCampaigns></soap:Body></soap:Envelope>';

        fetch(this.npsenseApiUrl, {
          method: 'POST',
          headers: {
            ...this.npsenseApiDataHeaders,
            'Content-Type': 'application/xml'
          },
          body: dataRequest
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Respuesta no exitosa desde el servidor');
            }
            return response.text();
          })
          .then(data => {
            const parser = new DOMParser();
            const result = parser.parseFromString(data, 'text/xml');
            const campaignResponse = JSON.parse(result.getElementsByTagName('ns1:apiGetCampaignsResponse')[0].textContent);
            const jsonTree = campaignResponse.campaign;
            const tempCampaigns = [];
            if (jsonTree) {
              jsonTree.forEach((camps) => {
                tempCampaigns.push({
                  id: camps['@attributes'].id,
                  name: camps['@attributes'].name,
                });
                this.campaignsAll.push({
                  id: camps['@attributes'].id,
                  name: camps['@attributes'].name,
                });
              });
              tempCampaigns.unshift({
                id: 'all',
                name: 'Todas las campañas',
              });
              this.campaigns = tempCampaigns;
            } else {
              this.errorApi = true;
              this.selectedCampaign = 'all';
            }
            this.$Progress.finish();
            this.$store.dispatch('saveGeoResponse', campaignResponse);
          })
          .catch(error => {
            // Intentamos analizar el error como XML, pero puede que no sea posible.
            try {
              const parser = new DOMParser();
              const result = parser.parseFromString(error.message, 'text/xml');
              const errorResponse = result.getElementsByTagName('faultstring')[0].textContent;
              alert('Ha ocurrido un error: ' + errorResponse);
            } catch (parsingError) {
              alert('Ha ocurrido un error desconocido.');
            }
            this.hideModal();
            this.$Progress.fail();
          });
      },
      setCampaignName(name) {
        this.selectedCampaignName = name
      },
      setCampaign: async function () {
        // Si se selecciona "Todas" guardamos en blanco para API
        const selectedCampaign = this.selectedCampaign === 'all' ? '' : this.selectedCampaign
        const campaignData = {
          ID: selectedCampaign,
          Name: this.selectedCampaignName
        }
        await this.$store.dispatch('setCampaignData', campaignData)
        await this.$store.dispatch('setWaveData', '')
        if (this.$store.state.centerData.Name && this.selectedCampaign !== 'all') {
          this.$router.push({ name: 'ConfigGeoWaves' })
        } else {
          this.$router.push({ name: 'Dashboard' })
        }
      },
    }
  }
</script>
