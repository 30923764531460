<template>
  <div class="dashboard-comp">
    <div class="topbar" :class="platform">
      <div class="close">
        <a href="#" class="btn btn-link btn-close" @click.prevent="hideModal">&times;</a>
      </div>
      <div class="titleCampaign">
        {{ this.$store.state.currentName }} <p class="subtitle">{{ campaignName }}</p>
      </div>
    </div>

    <div class="fondoAzul dashboard-popup-with-bottom" :class="platform">
      <template v-if="motives.length >= 1">
        <div v-for="(motive, index) in motives" :key="index" class="motive">
          <div class="container">
            <div class="grid">
              <div class="col-12">
                <div class="text">
                  {{ motive['@attributes'].motive }}
                </div>
                <div :class="motiveType">
                  <div class="bar" :style="{ 'width': (parseInt(motive.total_pctg) * 2.5) + 'px' }" />
                  {{ motive.total_pctg | addDecimal | addPercent }} ({{ motive.total_count }})
                </div>
                <div :class="motiveType">
                  <div class="bar" :style="{ 'width': (parseInt(motive.curyear_pctg) * 2.5) + 'px' }" />
                  {{ motive.curyear_pctg | addDecimal | addPercent }} ({{ motive.curyear_count }})
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="errorApi || motives.length === 0">
        <div class="text-center">
          <div class="spacer rem2" />
          <div class="spacer rem2" />
          <p class="alert">
            Lo sentimos, no hay datos asociados a la configuración especificada.
          </p>
        </div>
      </template>
      <template v-else>
        <div class="text-center">
          <div class="spacer rem2" />
          <img src="@/assets/images/loading.gif" width="21" alt="">
        </div>
      </template>
    </div>

    <div class="bottombar">
      <div class="container">
        <div class="grid grid-bleed">
          <div class="col-12 text-center motiveButtons">
            <button class="btn btn-icon btn-type" :class="motiveType === 'Promoter' ? 'active' : ''"
              @click="changeMotiveType('Promoter')">
              <img src="@/assets/images/bigico_npsTable_positive.png" width="22"
                srcset="@/assets/images/bigico_npsTable_positive@2x.png 2x, @/assets/images/bigico_npsTable_positive@3x.png 3x">
            </button>
            &nbsp;
            <button class="btn btn-icon btn-type" :class="motiveType === 'Passive' ? 'active' : ''"
              @click="changeMotiveType('Passive')">
              <img src="@/assets/images/bigico_npsTable_neutral.png" width="22"
                srcset="@/assets/images/bigico_npsTable_neutral@2x.png 2x, @/assets/images/bigico_npsTable_neutral@3x.png 3x">
            </button>
            &nbsp;
            <button class="btn btn-icon btn-type" :class="motiveType === 'Detractor' ? 'active' : ''"
              @click="changeMotiveType('Detractor')">
              <img src="@/assets/images/bigico_npsTable_negative.png" width="22"
                srcset="@/assets/images/bigico_npsTable_negative@2x.png 2x, @/assets/images/bigico_npsTable_negative@3x.png 3x">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { platformMixin } from '@/platformMixin'
import { loginMixin } from '@/loginMixin'

export default {
  filters: {
    addDecimal(value) {
      const valor = parseFloat(value)
      return valor.toFixed(2)
    },
    addPercent(value) {
      return `${value}%`
    },
  },
  mixins: [platformMixin, loginMixin],
  props: {
    dynamicCampaignId: {
      type: Number,
      required: true
    },
    dynamicCampaignName: {
      required: true
    }
  },
  data() {
    return {
      errorApi: false,
      geoName: this.$store.state.geoData.Name,
      motiveType: 'Promoter',
      motives: false
    }
  },
  computed: {
    campaignName() {
      let name
      if (this.dynamicCampaignName === '') {
        name = this.$store.state.campaignData.Name
      } else {
        name = this.dynamicCampaignName
      }
      return name
    }
  },
  mounted() {
    this.npsenseLogin(this.$store.state.login.user, this.$store.state.login.pass)
      .then(() => {
        setTimeout(() => {
          this.setMotiveData(this.dynamicCampaignId)
        }, 100)
      })
  },
  methods: {
    hideModal() {
      this.$emit('dashboard-motives-opened', false)
    },
    changeMotiveType(type) {
      this.motives = false
      this.motiveType = type
      this.setMotiveData(this.dynamicCampaignId)
    },
    setMotiveData(campaignIDprop) {
      this.$Progress.start()
      // Comprobamos qué ha seleccionado el usuario para hacer la consulta
      let geoID
      let centerID
      let campaignID
      if (campaignIDprop === '') {
        campaignID = this.$store.state.campaignData.ID
      } else {
        campaignID = campaignIDprop
      }
      geoID = this.$store.state.currentID
      centerID = this.$store.state.centerData.ID
      let waveID = ''
      if (this.$store.state.waveData.ID) {
        geoID = ''
        centerID = ''
        campaignID = ''
        waveID = this.$store.state.waveData.ID
      }
      if (centerID) {
        geoID = ''
      }
      // Si hemos indagado en la jerarquía hasta "centro", cambiamos las propiedades
      if (this.$store.state.currentHierarchy === 'center' && !this.$store.state.waveData.ID) {
        geoID = ''
        centerID = this.$store.state.currentID
      }
      // Realizamos la consulta API
      const dataRequest = '<?xml version="1.0" encoding="UTF-8"?><soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><soap:Body>' +
        '<apiGetMotives>' +
        '<sessionkey>' + this.$store.state.login.token + '</sessionkey>' +
        '<geo_id>' + geoID + '</geo_id>' +
        '<center_id>' + centerID + '</center_id>' +
        '<campaign_id>' + campaignID + '</campaign_id>' +
        '<wave_id>' + waveID + '</wave_id>' +
        '<motive_type>' + this.motiveType + '</motive_type>' +
        '<answer_type>json</answer_type>' +
        '</apiGetMotives></soap:Body></soap:Envelope>'

      const _app = this

      fetch(this.npsenseApiUrl, {
        method: 'POST',
        headers: this.npsenseApiDataHeaders,
        body: dataRequest
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then(data => {
          const parser = new DOMParser()
          const result = parser.parseFromString(data, 'text/xml')
          const motivesResponse = result.getElementsByTagName('ns1:apiGetMotivesResponse')[0]?.textContent
          const tempMotives = JSON.parse(motivesResponse)
          if (tempMotives.length === 0) {
            _app.motives = []
          } else {
            _app.motives = tempMotives.motive
          }
          _app.$Progress.finish()
        })
        .catch(error => {
          _app.$Progress.fail()
          console.error('Error:', error)
          const parser = new DOMParser()
          const result = parser.parseFromString(error.message, 'text/xml')
          const errorResponse = result.getElementsByTagName('faultstring')[0]?.textContent || "Error desconocido"
          alert('Ha ocurrido un error: ' + errorResponse)
          _app.hideModal()
        });
    }
  }
}
</script>
