import { store } from './store/store';

export function getNpsenseApiUrl() {
  const loginServer = store.getters.getLoginServer;

  if (process.env.NODE_ENV === 'development') {
    return loginServer
      ? 'https://bopre-latam.opinat.com/index.php/ws/npsense-soap/ws?ws=1'
      : 'https://bopre-emea.opinat.com/index.php/ws/npsense-soap/ws?ws=1';
  } else {
    return loginServer
      ? 'https://bo-latam.opinat.com/index.php/ws/npsense-soap/ws?ws=1'
      : 'https://bo-emea.opinat.com/index.php/ws/npsense-soap/ws?ws=1';
  }
}

export const npsenseApiDataHeaders = {
  'Content-type': 'text/xml; charset=utf-8'
};
