import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [createPersistedState({
    key: 'NPSense'
  })],
  // State
  state: {
    version: null,
    platform: null,
    loginServer: false,
    login: {
      user: null,
      pass: null,
      token: null,
      time: null
    },
    geoResponse: null,
    geoSelected: null,
    currentHierarchy: null,
    currentID: null,
    currentName: null,
    geoBrothers: null,
    geoData: null,
    campaignData: null,
    centerData: null,
    waveData: null,
    continent: null,
    country: null,
    zone: null,
    region: null,
    area: null,
    center: null,
    familyNav: []
  },

  // Mutations
  mutations: {
    SET_VERSION(state, payload) {
      state.version = payload
    },
    SET_PLATFORM(state, payload) {
      state.platform = payload
    },
    SET_LOGIN_SERVER(state, payload) {
      state.loginServer = payload
    },
    SET_LOGIN(state, payload) {
      state.login = {
        user: payload.user,
        pass: payload.pass,
        token: payload.token,
        time: payload.time
      }
    },
    CLEAR_NPSENSE_DATA(state) {
      state.geoResponse = null
      state.geoSelected = null
      state.currentHierarchy = null
      state.currentID = null
      state.currentName = null
      state.geoBrothers = null
      state.geoData = null
      state.centerData = null
      state.campaignData = null
      state.waveData = null
      state.continent = null
      state.country = null
      state.zone = null
      state.region = null
      state.area = null
      state.center = null
      state.familyNav = []
    },
    SET_GEO_RESPONSE(state, payload) {
      state.geoResponse = payload
    },
    SET_GEO_SELECTED(state, payload) {
      state.geoSelected = payload
    },
    SET_GEO_BROTHERS(state, payload) {
      state.geoBrothers = payload
    },
    SET_GEO_DATA(state, payload) {
      state.geoData = payload
    },
    SET_GEO_LEVEL_CONTINENT(state, payload) {
      state.continent = payload
    },
    SET_GEO_LEVEL_COUNTRY(state, payload) {
      state.country = payload
    },
    SET_GEO_LEVEL_ZONE(state, payload) {
      state.zone = payload
    },
    SET_GEO_LEVEL_REGION(state, payload) {
      state.region = payload
    },
    SET_GEO_LEVEL_AREA(state, payload) {
      state.area = payload
    },
    SET_GEO_LEVEL_CENTER(state, payload) {
      state.center = payload
    },
    SET_CURRENT_HIERARCHY(state, payload) {
      state.currentHierarchy = payload
    },
    SET_CURRENT_ID(state, payload) {
      state.currentID = payload
    },
    SET_CURRENT_NAME(state, payload) {
      state.currentName = payload
    },
    SET_CENTER_DATA(state, payload) {
      state.centerData = payload
    },
    SET_CAMPAIGN_DATA(state, payload) {
      state.campaignData = payload
    },
    SET_WAVE_DATA(state, payload) {
      state.waveData = payload
    },
    SET_FAMILY_NAV(state, payload) {
      state.familyNav = payload
    }
  },

  // Actions
  actions: {
    setVersion({commit}, payload) {
      commit('SET_VERSION', payload)
    },
    setLoginData({commit}, payload) {
      commit('SET_LOGIN', payload)
    },
    clearLoginData({commit}) {
      commit('SET_LOGIN', {
        user: null,
        pass: null,
        token: null
      })
    },
    clearGeoResponse({commit}) {
      commit('SET_GEO_RESPONSE', null)
    },
    clearNPSenseData({commit}) {
      commit('CLEAR_NPSENSE_DATA')
    },
    saveGeoResponse({commit}, payload) {
      commit('SET_GEO_RESPONSE', payload)
    },
    setGeoSelected({commit}, payload) {
      commit('SET_GEO_SELECTED', payload)
    },
    setGeoBrothers({commit}, payload) {
      commit('SET_GEO_BROTHERS', payload)
    },
    setGeoData({commit}, payload) {
      commit('SET_GEO_DATA', payload)
    },
    setGeoLevels({commit}, payload) {
      commit('SET_GEO_LEVEL_'+payload.name, payload.data)
    },
    setCenterData({commit}, payload) {
      commit('SET_CENTER_DATA', payload)
    },
    setCampaignData({commit}, payload) {
      commit('SET_CAMPAIGN_DATA', payload)
    },
    setWaveData({commit}, payload) {
      commit('SET_WAVE_DATA', payload)
    },
    setFamilyNav({commit}, payload) {
      commit('SET_FAMILY_NAV', payload)
    },
    setCurrentHierarchy({commit}, payload) {
      commit('SET_CURRENT_HIERARCHY', payload)
    },
    setCurrentID({commit}, payload) {
      commit('SET_CURRENT_ID', payload)
    },
    setCurrentName({commit}, payload) {
      commit('SET_CURRENT_NAME', payload)
    }
  },

  // Getters
  getters: {
    getLoginServer: state => {
      return state.loginServer
    },
    getLoginData: state => {
      return state.login
    },
    getTokenTime: state => {
      return state.login.time
    },
    getGeoResponse: state => {
      return state.geoResponse
        ? state.geoResponse
        : null
    },
    getGeoBrothers: state => {
      return state.geoBrothers
        ? state.geoBrothers
        : null
    },
    getWaves: state => {
      return state.geoResponse
        ? state.geoResponse
        : null
    },
    brothersCount: state => {
      return state.geoBrothers
        ? state.geoBrothers.length
        : 0
    }
  }
})
