<template>
  <div class="config">
    <div
      class="topbar"
      :class="platform"
    >
      <div class="container">
        <div class="grid grid-bleed">
          <div class="col-12">
            <!-- <button
              class="btn btn-link btn-close"
              @click="clearLogin"
            >
              &times;
            </button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div
        class="fondoAzul config-process-full"
        :class="platform"
      >
        <div class="grid justify-center align-center">
          <div class="col-10">
            <div class="content">
              <div class="spacer rem2" />
              <img
                src="@/assets/images/big_ico_app.png"
                srcset="@/assets/images/big_ico_app@2x.png 2x, @/assets/images/big_ico_app@3x.png 3x"
                width="100"
              >
              <h1>Bienvenido a NPSense</h1>
              <div class="spacer rem1" />
              <p>A continuación procederemos a una sencilla configuración para que puedas empezar a monitorizar la satisfacción de tus clientes.</p>
              <router-link
                :to="{name: 'ConfigGeoHierarchy'}"
                class="btn btn-icon"
              >
                Configurar
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { platformMixin } from '@/platformMixin'

  export default {
    mixins: [platformMixin],
    methods: {
      async clearLogin() {
        await this.$store.dispatch('clearLoginData')
        this.$router.push('/')
      }
    }
  }
</script>
